import {
  UploadItem,
  GalleyType,
  GalleryTab,
  UploadStatus,
  UploadCategory,
} from '../types/Files'
import { Constants, Util, MessageUtils } from '../common'
import { Thread } from '../types/Thread'
import { uploadObject } from '../common/S3Util'
import * as Sentry from '@sentry/react'

async function showFileChooser(
  uploadItemTypes?: GalleyType[],
  multiple = true
): Promise<UploadItem[]> {
  const types = [
    {
      description: 'Images',
      name: GalleyType.photo,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/webp': ['webp'],
      },
    },
    {
      description: 'Videos',
      name: GalleyType.video,
      accept: {
        'video/mp4': ['.mp4'],
      },
    },
    {
      description: 'Audios',
      name: GalleyType.audio,
      accept: {
        'audio/mpeg': ['.mp3'],
      },
    },
    {
      description: 'Files',
      name: GalleyType.docs,
      accept: {
        'application/pdf': ['.pdf'],
      },
    },
  ]

  const filteredTypes = uploadItemTypes
    ? types.filter((type) => !uploadItemTypes.includes(type.name))
    : types

  let files: FileSystemFileHandle[] = []
  try {
    files = await window.showOpenFilePicker({
      types: filteredTypes,
      multiple: multiple,
      excludeAcceptAllOption: true,
    })
  } catch (error) {}

  const items: UploadItem[] = []
  if (files && files.length > 0) {
    if (files.length > Constants.MAX_UPLOAD_ITEM_COUNT) {
      Util.showErrorMessage(
        'Maximum number of items you can send through chat at once is ' +
          Constants.MAX_UPLOAD_ITEM_COUNT
      )
      files = files.slice(0, Constants.MAX_UPLOAD_ITEM_COUNT)
    }

    const promises = files.map(async (file: FileSystemFileHandle) => {
      const fileObj: File = await file.getFile()

      const commonFileAttributes = {
        uploadStatus: UploadStatus.uploading,
        uploadingStatusValue: 0,
        gtCreatedTime: new Date().toISOString(),
        gtUpdatedTime: new Date().toISOString(),
      }

      if (
        fileObj.type === 'image/jpeg' ||
        fileObj.type === 'image/png' ||
        fileObj.type === 'image/webp'
      ) {
        const buffer = await fileObj.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))
        const key = `images/${Util.generateUUID()}`
        items.push({
          recordKey: `${GalleyType.photo}_${key}`,
          objectId: key,
          fileName: fileObj.name,
          galleryType: GalleyType.photo,
          file: fileObj,
          uploadingUrl: url,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.photo,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (fileObj.type === 'video/mp4') {
        const key = `videos/${Util.generateUUID()}`
        const buffer = await fileObj.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))

        items.push({
          recordKey: `${GalleyType.video}_${key}`,
          objectId: key,
          fileName: fileObj.name,
          galleryType: GalleyType.video,
          file: fileObj,
          uploadingUrl: url,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.video,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (fileObj.type === 'audio/mpeg') {
        const key = `audios/${Util.generateUUID()}`
        items.push({
          recordKey: `${GalleyType.audio}_${key}`,
          objectId: key,
          fileName: fileObj.name,
          galleryType: GalleyType.audio,
          file: fileObj,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.audio,
          ...commonFileAttributes,
        } as UploadItem)
      } else {
        const key = `${Util.generateUUID()}`
        const buffer = await fileObj.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))

        items.push({
          recordKey: `${GalleyType.docs}_${key}`,
          objectId: key,
          fileName: fileObj.name,
          galleryType: GalleyType.docs,
          file: fileObj,
          uploadingUrl: url,
          tab: GalleryTab.docs,
          uploadCategory: UploadCategory.document,
          ...commonFileAttributes,
        } as UploadItem)
      }
    })
    await Promise.all(promises)
    return items
  } else {
    return items
  }
}

async function showMediaChooser() {
  return showFileChooser([GalleyType.audio, GalleyType.photo, GalleyType.video])
}

async function showDocChooser() {
  return showFileChooser([GalleyType.docs])
}

async function showAllFileChooser() {
  return showFileChooser()
}

function getUploadMetaData(
  thread: Thread | null,
  item: UploadItem,
  orgId: string,
  extraProps?: { [key: string]: string }
) {
  const effectiveUserId = thread
    ? MessageUtils.getEffectiveUserId(thread, item.uploadedByUserId)
    : null
  return Util.removeUndefinedProps({
    'media-category': item.tab,
    'thread-id': thread?.threadId || undefined,
    origin: thread
      ? Constants.MEDIA_ORIGIN_TYPES.THREAD
      : Constants.MEDIA_ORIGIN_TYPES.BUSINESS_PROFILE,
    'origin-id': thread ? thread.threadId : orgId,
    'uploaded-by-user-id': item.uploadedByUserId,
    'business-id': orgId,
    'uploaded-by-effective-user-id': effectiveUserId || undefined,
    'file-name': item.fileName,
    extension: Util.getFileExtension(item.fileName as string),
    'media-source': 'web',
    ...extraProps,
  })
}

async function uploadFileToS3(
  item: UploadItem,
  orgId: string,
  thread: Thread | null,
  onProgressChange: (key: string, progress: number) => void,
  extraProps?: { [key: string]: string }
) {
  try {
    // upload thumbnail if only video
    if (item.galleryType === GalleyType.video) {
      const thumbKey = MessageUtils.getThumbnailKeyFromVideoObjectKey(
        item.objectId
      )
      //create thumbnail
      const thumb = await MessageUtils.createThumbnail(item.file as File, 10000)

      const thumbFile = MessageUtils.dataURLtoFile(
        String(thumb),
        thumbKey || ''
      )
      //upload thumbnail

      const thumbUploaded = await uploadObject(
        thumbKey || '',
        thumbFile,
        getUploadMetaData(thread, item, orgId, extraProps),
        process.env.MEDIA_S3_BUCKET,
        () => onProgressChange(thumbKey as string, 1) //no need to set progress to thumbnail
      )
      if (!thumbUploaded) {
        throw new Error('Thumbnail upload failed')
      }
    }
    //upload media
    const isDoc = item.galleryType === GalleyType.docs

    const uploaded = await uploadObject(
      item.objectId,
      item.file as File,
      getUploadMetaData(thread, item, orgId),
      isDoc ? process.env.DOCUMENTS_S3_BUCKET : process.env.MEDIA_S3_BUCKET,
      onProgressChange
    )
    if (!uploaded) {
      throw new Error(
        `${isDoc ? `Document upload failed` : 'Media item upload failed'} [${
          item.fileName
        }]`
      )
    }

    return {
      state: true,
      item: item,
    }
  } catch (error: any) {
    Sentry.captureException(error, {
      tags: { upload_util: 'upload_file_to_s3' },
      level: 'error',
    })

    return {
      state: false,
      item: item,
      error: error?.message,
    }
  }
}

export { showFileChooser, showMediaChooser, showDocChooser, uploadFileToS3 }
