import { FlatList, StyleSheet, View, TouchableOpacity } from 'react-native'
import { AppColors, ComponentOptions, Icon } from '@gotradie/gt-components'
import { GalleyType, UploadItem } from '../../types/Files'
import Image from '../media/Image'
import { Constants, Util } from '../../common'
import FileChooser from '../FileChooser'

const styles = StyleSheet.create({
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: ComponentOptions.SPACES.SMALL,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: ComponentOptions.SPACES.SMALL,
  },
  fileUploadIconStyle: {
    backgroundColor: AppColors.common.tertiary,
  },
  footerContentStyle: {
    flexDirection: 'row',
    width: '110%',
  },
  detailedCameraIconWrapper: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
})

type ClipImageUploaderProps = {
  onSelectFiles: (items: UploadItem[]) => void
  onPressMedia: (index: number) => void
  media: UploadItem[]
  onPressOpenGallery?: (isVisible: boolean) => void
}

function ClipImageUploader({
  onPressOpenGallery,
  media,
  onPressMedia,
  onSelectFiles,
}: ClipImageUploaderProps) {
  const renderUploadItem = (item: UploadItem, index: number) => {
    const isVideo = item.galleryType === GalleyType.video

    const isUploaded =
      item.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED

    const thumbnailKey = isUploaded
      ? (Util.getThumbnailKeyFromVideoObjectKey(item.objectId) as string)
      : undefined
    return (
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() => onPressMedia(index)}
      >
        <Image
          source={
            isUploaded ? undefined : isVideo ? item.thumbUrl : item.uploadingUrl
          }
          imageProps={{
            style: {
              width: 48,
              height: 48,
              resizeMode: 'cover',
              borderRadius: ComponentOptions.SPACES.SMALL,
            },
          }}
          s3Key={
            isUploaded ? (isVideo ? thumbnailKey : item.objectId) : undefined
          }
          size={Constants.IMAGE_SIZES.GALLERY_THUMB}
        />
        {isVideo && (
          <View style={styles.detailedCameraIconWrapper}>
            <Icon
              name={'DetailedCamera3'}
              fill={AppColors.common.white}
              size={10}
            />
          </View>
        )}
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.footerContentStyle}>
      {onPressOpenGallery && (
        <TouchableOpacity
          style={[styles.iconContainer, styles.fileUploadIconStyle]}
          onPress={() => {
            onPressOpenGallery(true)
          }}
        >
          <Icon name={'Add'} size={20} fill={AppColors.common.grey} />
        </TouchableOpacity>
      )}

      {onPressOpenGallery === undefined && (
        <View style={[styles.iconContainer, styles.fileUploadIconStyle]}>
          <FileChooser
            displayElement={
              <Icon name={'Add'} size={20} fill={AppColors.common.grey} />
            }
            isMultiple={true}
            isFileItem={false}
            onSelectFiles={(items) => {
              const filteredList = items.filter((i) =>
                Constants.CLIP_ACCEPTED_MIME_TYPES.includes(i.file?.type || '')
              )
              onSelectFiles(filteredList)
            }}
            containerStyle={{}}
            disabled={false}
          />
        </View>
      )}
      <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        data={media}
        extraData={media}
        renderItem={({ item, index }) => renderUploadItem(item, index)}
        keyExtractor={(item) => item.objectId}
      />
    </View>
  )
}

export default ClipImageUploader
