import { useQuery } from 'react-query'
import { QueryKeys, getAxios, Constants, Util } from '../../common'
import {
  ABN,
  Address,
  Business,
  BusinessTrade,
  Coordinate,
  Highlight,
  Member,
  Owner,
  RequestedMember,
  Service,
  Rate,
  ServiceArea,
  License,
  BusinessProfileCompletion,
  BusinessProfileInCompletedFields,
  UnClaimedBusinessInfo,
  Supplier,
  ConsumptionData,
  AccountStorageData,
  ConsumptionPlan,
  ConsumptionMetaData,
} from '../../types/Business'
import { ServerError } from '../../types/Common'
import {
  BusinessSearchResult,
  unclaimedBusinessDataProps,
} from '../../types/SignUp'
import { dtoToUserRole } from '../user/useFetchUser'
import { useActiveOrgId, useActiveUserId } from '../../context/UserContext'

const api = getAxios()
const apiWithoutAuth = getAxios(false)

/*====================== Fetch Business ================================*/
const fetchBusiness = async (businessId: string) => {
  return await api.get(`/business/${businessId}`)
}

export const useFetchBusiness = (businessId: string, enabled = true) => {
  return useQuery(
    [QueryKeys.BUSINESS, businessId],
    () => fetchBusiness(businessId),
    {
      enabled,
      select: (data) => {
        const business = data?.data?.business
        if (business) {
          return dtoToBusiness(business)
        }
        return undefined
      },
      cacheTime: Constants.DURATION.MINUTE * 10,
      staleTime: Constants.DURATION.WEEK * 10,
    }
  )
}

/*====================== Find Businesses ================================*/
/* Called inside main search screen */
async function findBusinesses(query: string) {
  return await api.get(`/business/find${query}`)
}
export const useFindBusinesses = (enabled = true, query: string) => {
  return useQuery(
    [QueryKeys.SEARCH_BUSINESSES, query, useActiveOrgId()],
    () => findBusinesses(query),
    {
      enabled,
      select: (data) => {
        const businesses = data?.data?.business
        if (businesses) {
          return (businesses || []).map((obj: any) => {
            return dtoToBusiness(obj)
          })
        }
        return []
      },
    }
  )
}

/*====================== fetchTeamMembers ================================*/

const fetchTeamMembers = async (businessId: string) => {
  return await api.get(`/business/${businessId}/members`)
}
export const useFetchTeamMembers = (
  businessId: string,
  enabled = true,
  onError = () => {},
  acceptedOnly = true,
  skipOwner = true
) => {
  const userId = useActiveUserId()
  return useQuery(
    [QueryKeys.TEAM_MEMBERS, businessId],
    () => fetchTeamMembers(businessId),
    {
      enabled,
      onError,
      select: (data) => {
        let members: Member[] = (data?.data?.members || []).map((obj: any) => {
          return {
            ...dtoToBusinessMember({ ...obj, 'business-id': businessId }),
            type: Constants.MEMBER_TYPE.MEMBER,
          } as Member
        })

        if (skipOwner) {
          members = members.filter((member) => member.userId !== userId)
        }

        if (acceptedOnly) {
          return members
        } else {
          const pendingMembers: Member[] = (
            data?.data?.['pending-invites'] || []
          ).map((obj: any) => {
            return {
              ...dtoToBusinessMember({ ...obj, 'business-id': businessId }),
              type: Constants.MEMBER_TYPE.PENDING_MEMBER,
            } as Member
          })
          return [...members, ...pendingMembers]
        }
      },
    }
  )
}

/*====================== search business using facets ================================*/
/*
 * This call for used for searching businesses when worker signups/requests for a business.
 * To find businesses with more options, use {@findBusinesses} call.
 */
type searchBusinessUsingFacetsParam = {
  query: string
  facets: any
}

const searchBusinessUsingFacets = async ({
  query,
  facets,
}: searchBusinessUsingFacetsParam) => {
  return await api.get(`/business/search?query=${query}&facets=${facets}`)
}

export const useSearchBusinessUsingFacets = (
  query: string,
  facets: any,
  enabled = true,
  onErrorCallback: (e: ServerError) => void,
  onSuccessCallback?: (data: any) => void
) => {
  return useQuery(
    QueryKeys.BUSINESS_SEARCH_WITH_FACETS,
    () => searchBusinessUsingFacets({ query, facets }),
    {
      initialData: undefined,
      enabled,
      select: (data) => {
        return (data?.data?.business || []).map(
          (v: any) =>
            ({
              businessName: v['business-name'],
              handle: v['handle'],
              businessId: v['business-id'],
              trades: v['trades'],
              businessLogo: v['business-logo'],
              ...v,
            } as BusinessSearchResult)
        )
      },
      onSuccess: (data: any) => {
        onSuccessCallback && onSuccessCallback(data)
      },
      onError: (error) => {
        onErrorCallback(error as ServerError)
      },
    }
  )
}

/*====================== Generate handler name ================================*/

type generateHandlerParam = {
  businessName: string
}

const generateHandler = async ({ businessName }: generateHandlerParam) => {
  return await api.get(`/business/generatehandle?business-name=${businessName}`)
}

export const useGenerateHandler = (
  businessName: string,
  enabled = true,
  onErrorCallback: (e: ServerError) => void
) => {
  return useQuery(
    QueryKeys.BUSINESS_GENERATE_HANDLER,
    () => generateHandler({ businessName }),
    {
      initialData: undefined,
      enabled,
      select: (data) => {
        return data?.data?.handle || ''
      },
      onError: (error) => {
        onErrorCallback(error as ServerError)
      },
    }
  )
}
/*====================== Fetch business Licences ================================*/
const fetchBusinessLicenses = async (businessId: string) => {
  return await api.get(`/business/${businessId}/licenses`)
}

export const useFetchBusinessLicenses = (
  businessId: string,
  enabled = true
) => {
  return useQuery(
    [QueryKeys.BUSINESS_LICENSES, businessId],
    () => fetchBusinessLicenses(businessId),
    {
      enabled,
      select: (data) => {
        const licenses = data.data?.['nsw-tradie-licence'] ?? []
        return licenses.map((license: any) => dtoToBusinessLicense(license))
      },
    }
  )
}
/*====================== Fetch business Roles ================================*/
const fetchBusinessRoles = async (businessId: string) => {
  return await api.get(`/business/${businessId}/roles`)
}
export const useFetchBusinessRoles = (
  businessId: string,
  enabled = true,
  skipOwner = false
) => {
  return useQuery(
    [QueryKeys.BUSINESS_ROLES, businessId],
    () => fetchBusinessRoles(businessId),
    {
      initialData: undefined,
      enabled,
      select: (data) => {
        const roles = data?.data?.roles || {}
        const updated = Object.values(roles).map((role) => dtoToUserRole(role))
        if (skipOwner) {
          return updated.filter(
            (role) =>
              role.roleTemplateId !==
              Constants.USER_ROLE_TEMPLATES.default_tradie_owner
          )
        } else {
          return updated
        }
      },
    }
  )
}

/*====================== Fetch business requested members ================================*/
const fetchBusinessRequestedMembers = async (businessId: string) => {
  return await api.get(`/business/${businessId}/requested-members`)
}

export const useFetchBusinessRequestedMembers = (
  businessId: string,
  enabled = true,
  reqByUserOnly = false
) => {
  return useQuery(
    [QueryKeys.BUSINESS_REQUESTED_MEMBERS, businessId],
    () => fetchBusinessRequestedMembers(businessId),
    {
      enabled,
      select: (data: any) => {
        const requestedMembers = (data?.data?.['requested-members'] || []).map(
          (obj: any) => {
            return {
              ...dtoToBusinessMember(obj),
              type: Constants.INVITE_REQ_TYPES.REQ_BY_USER,
            }
          }
        )
        let requestedInvites = []
        if (!reqByUserOnly) {
          requestedInvites = (data?.data?.['requested-invites'] || []).map(
            (obj: any) => {
              return {
                ...dtoToBusinessMember(obj),
                type: Constants.INVITE_REQ_TYPES.REQ_BY_BUSINESS_OWNER,
              }
            }
          )
        }
        let expiredInvites = []
        if (!reqByUserOnly) {
          expiredInvites = (data?.data?.['expired-invites'] || []).map(
            (obj: any) => {
              return {
                ...dtoToBusinessMember(obj),
                type: Constants.INVITE_REQ_TYPES.REQ_BY_BUSINESS_OWNER,
              }
            }
          )
        }
        return [...requestedMembers, ...requestedInvites, ...expiredInvites]
      },
    }
  )
}

const fetchTradeServices = async (query: any) => {
  return await api.get(`/business/metadata/services${query}`)
}
export const useFetchTradeServices = (
  query: any,
  enabled = true,
  businessId: string
) => {
  return useQuery(
    [QueryKeys.BUSINESS_TRADE_SERVICES, businessId],
    () => fetchTradeServices(Util.generateQueryParameter(query)),
    {
      enabled,
      select: (data) => {
        const services: Service[] = []
        const servicesArray = data?.data?.services
        if (servicesArray) {
          for (const value of servicesArray) {
            const item: Service = {} as Service
            item.serviceId = value['service-id']
            item.serviceHashtag = value['service-hashtag']
            item.name = value['name'] ? value['name'] : value
            services.push(item)
          }
          return services
        }
        return []
      },
      cacheTime: Constants.DURATION.MINUTE * 10,
      staleTime: Constants.DURATION.WEEK * 10,
    }
  )
}

const fetchBusinessServiceAreas = async () => {
  return await api.get('/business/metadata/service-areas')
}
export const useFetchBusinessServiceAreas = (enabled = true) => {
  return useQuery(
    [QueryKeys.BUSINESS_SERVICE_AREAS, useActiveOrgId()],
    () => fetchBusinessServiceAreas(),
    {
      enabled,
      select: (data) => {
        const serviceAreas = data.data?.['service-areas'] ?? []
        return serviceAreas.map((serviceArea: any) =>
          dtoToBusinessServiceArea(serviceArea)
        )
      },
    }
  )
}

const fetchABNList = async (query: string) => {
  const data = {
    name: query,
  }
  return await api.post('/tradie/abnlookup', data)
}
export const useFetchABNList = (query: string, enabled = true) => {
  return useQuery(
    [QueryKeys.ABN_LIST, useActiveOrgId(), query],
    () => fetchABNList(query),
    {
      enabled,
      select: (data) => {
        const abnList = data.data?.names ?? []
        return abnList.map((abn: any) => dtoToABN(abn))
      },
    }
  )
}

function dtoToABN(obj: any) {
  const abn = {} as ABN
  abn.isCurrent = obj.IsCurrent
  abn.score = obj.Score
  abn.state = obj.State
  abn.nameType = obj.NameType
  abn.abn = obj.Abn
  abn.abnStatus = obj.AbnStatus
  abn.postcode = obj.Postcode
  abn.name = obj.Name
  return abn
}

function dtoToBusinessServiceArea(value: any) {
  const area = {} as ServiceArea
  area.area_name = value['area-name']
  area.geohash_values = value['geohash-values']
  area.image_url = value['image-url']
  area.pictol_cordinates = value['pictol-cordinates']
  area.post_codes = value['post-codes']
  area.suburbs = value.suburbs
  area.areaName = value['area-name']
  area.geohashValues = value['geohash-values']
  area.imageUrl = value['image-url']
  area.pictolCordinates = value['pictol-cordinates']
  area.postCodes = value['post-codes']

  return area
}

/*====================== Fetch business account storage ================================*/
export const fetchBusinessAccountStorage = async (businessId: string) => {
  return await api.get(`/business/${businessId}/consumptiondata`)
}

export function dtoToBusinessAccountStorage(response: any) {
  const accountStorage: AccountStorageData = {
    consumptionData: {} as ConsumptionData,
    plan: {} as ConsumptionPlan,
    metaData: {} as ConsumptionMetaData,
  }

  accountStorage.consumptionData.audioCount =
    response['consumption-data']['audio-count']
  accountStorage.consumptionData.audioData =
    response['consumption-data']['audio-data']
  accountStorage.consumptionData.businessId =
    response['consumption-data']['business-id']
  accountStorage.consumptionData.businessName =
    response['consumption-data']['business-name']
  accountStorage.consumptionData.clipMediaCount =
    response['consumption-data']['clip-media-count']
  accountStorage.consumptionData.clipMediaData =
    response['consumption-data']['clip-media-data']
  accountStorage.consumptionData.documentCount =
    response['consumption-data']['document-count']
  accountStorage.consumptionData.documentData =
    response['consumption-data']['document-data']
  accountStorage.consumptionData.email = response['consumption-data'].email
  accountStorage.consumptionData.messageMediaCount =
    response['consumption-data']['message-media-count']
  accountStorage.consumptionData.messageMediaData =
    response['consumption-data']['message-media-data']
  accountStorage.consumptionData.misc = response['consumption-data'].misc
  accountStorage.consumptionData.ownerName =
    response['consumption-data']['owner-name']
  accountStorage.consumptionData.phoneNumber =
    response['consumption-data']['phone-number']
  accountStorage.consumptionData.photoCount =
    response['consumption-data']['photo-count']
  accountStorage.consumptionData.photoData =
    response['consumption-data']['photo-data']
  accountStorage.consumptionData.runningTotal =
    response['consumption-data']['running-total']
  accountStorage.consumptionData.teamCount =
    response['consumption-data']['team-count']
  accountStorage.consumptionData.totalData =
    response['consumption-data']['total-data']
  accountStorage.consumptionData.videoCount =
    response['consumption-data']['video-count']
  accountStorage.consumptionData.videoData =
    response['consumption-data']['video-data']

  accountStorage.metaData.startedTime = response.metadata['started-time']
  accountStorage.metaData.endTime = response.metadata['end-time']
  accountStorage.plan.planLimit = response.plan['plan-limit']

  return accountStorage
}

/*====================== Data transfer to object ================================*/
export function dtoToBusiness(businessResponse: any) {
  const business = {} as Business
  business.abnNo = businessResponse['abn']
    ? businessResponse['abn'].Abn
      ? businessResponse['abn'].Abn
      : ''
    : ''
  business.handle = businessResponse['handle']
  business.businessLogo = businessResponse['business-logo']

  const businessTrades: BusinessTrade[] = []
  const trades: string[] = []
  for (const key of Object.keys(businessResponse?.['business-trades'] || {})) {
    const obj = businessResponse['business-trades'][key]
    businessTrades.push({
      tradeHash: obj['trade-hash'],
      tradeName: obj['trade-name'],
    })
    trades.push(obj['trade-name'])
  }

  business.businessTrades = businessTrades
  business.trades =
    trades.length > 0 ? trades : businessResponse?.['trades'] || []

  business.businessId = businessResponse['business-id']
  business.businessName = businessResponse['business-name']
  business.businessLogoUrl = businessResponse['business-logo-url']
  business.phoneNumber = businessResponse['phone-number']
  business.lcExpired = Util.replaceUndefinedWithDefault(
    businessResponse['lc-expired'],
    true
  )

  const services: Service[] = []
  if (businessResponse['services']) {
    for (const key of Object.keys(businessResponse['services'])) {
      const value = businessResponse['services'][key]
      const item: Service = {} as Service
      item.serviceId = value['service-id']
      item.serviceHashtag = value['service-hashtag']
      item.name = value['name'] ? value['name'] : value
      services.push(item)
    }
  }
  business.services = services

  const owners: Owner[] = []
  if (businessResponse['owners']) {
    for (const key of Object.keys(businessResponse['owners'])) {
      const item = businessResponse['owners'][key]
      const businessTrades: BusinessTrade[] = []
      const trades: string[] = []
      if (item?.['business-trades']) {
        for (const key2 of Object.keys(item['business-trades'])) {
          const item2 = item['business-trades'][key2]
          businessTrades.push({
            tradeHash: item2['trade-hash'],
            tradeName: item2['trade-name'],
          } as BusinessTrade)
          trades.push(item2['trade-name'])
        }
      }
      if (item) {
        owners.push({
          firstName: item['first-name'],
          lastName: item?.['last-name'] || '',
          phoneNumber: item?.['phone-number'] || '',
          profilePic: item?.['profile-pic'] || '',
          roleTemplateDisplayName: item?.['role-template-display-name'] || '',
          roleType: item?.['role-type'] || '',
          status: item?.['status'] || '',
          trades,
          businessTrades,
          userId: item['user-id'],
        } as Owner)
      }
    }
  }
  business.owners = owners

  const members: Member[] = []
  if (businessResponse['members']) {
    for (const key of Object.keys(businessResponse['members'])) {
      const item = businessResponse['members'][key]
      const businessTrades: BusinessTrade[] = []
      const trades: string[] = []
      if (item?.['business-trades']) {
        for (const key2 of Object.keys(item['business-trades'])) {
          const item2 = item['business-trades'][key2]
          businessTrades.push({
            tradeHash: item2['trade-hash'],
            tradeName: item2['trade-name'],
          } as BusinessTrade)
          trades.push(item2['trade-name'])
        }
      }
      if (item) {
        members.push({
          firstName: item['first-name'],
          lastName: item['last-name'],
          profilePic: item['profile-pic'],
          roleTemplateDisplayName: item['role-template-display-name'],
          roleType: item['role-type'],
          status: item['status'],
          effectiveUserId: item['effective-user-id'],
          notificationId: item['notification-id'],
          notificationStatus: item['notification-status'],
          userId: item['user-id'],
          viewJoinCongrats: item['view-join-congrats'],
          trades,
          businessTrades,
        } as Member)
      }
    }
  }
  business.members = members

  const reqMembers: RequestedMember[] = []
  if (businessResponse['requested-members']) {
    for (const key of Object.keys(businessResponse['requested-members'])) {
      const item = businessResponse['requested-members'][key]
      const businessTrades: BusinessTrade[] = []
      const trades: string[] = []
      if (item['business-trades']) {
        for (const key2 of Object.keys(item['business-trades'])) {
          const item2 = item['business-trades'][key2]
          businessTrades.push({
            tradeHash: item2['trade-hash'],
            tradeName: item2['trade-name'],
          } as BusinessTrade)
          trades.push(item2['trade-name'])
        }
      }
      reqMembers.push({
        effectiveUserId: item['effective-user-id'],
        firstName: item['first-name'],
        lastName: item['last-name'],
        notificationId: item['notification-id'],
        notificationStatus: item['notification-status'],
        profilePic: item['profile-pic'],
        requestedSource: item['requested-source'],
        roleTemplateDisplayName: item['role-template-display-name'],
        roleType: item['role-type'],
        status: item['status'],
        userId: item['user-id'],
        viewHoldTightPendingBusiness: item['view-hold-tight-pending-business'],
        viewJoinCongrats: item['view-join-congrats'],
        businessTrades,
        trades,
      } as RequestedMember)
    }
  }
  business.requestedMembers = reqMembers

  const address = {} as Address
  if (businessResponse['address']) {
    const addressResponse = businessResponse['address']
    address.address = addressResponse['address']
    address.postalCode = addressResponse['postal-code']
    address.route = addressResponse['route']
    address.state = addressResponse['state']
    address.streetNumber = addressResponse['street-number']
    address.subpremise = addressResponse['subpremise']
    address.suburb = addressResponse['suburb']
    if (addressResponse['cordinates']) {
      const cordinatesResponse = addressResponse['cordinates']
      address.cordinates = {
        latitude: cordinatesResponse['latitude'],
        longitude: cordinatesResponse['longitude'],
      } as Coordinate
    }
    address.description = addressResponse['description']
  }
  business.address = address

  const abn = {} as ABN
  if (businessResponse['abn']) {
    const abnResponse = businessResponse['abn']
    abn.abn = abnResponse['Abn']
    abn.abnStatus = abnResponse['AbnStatus']
    abn.isCurrent = abnResponse['IsCurrent']
    abn.name = abnResponse['Name']
    abn.nameType = abnResponse['NameType']
    abn.postcode = abnResponse['PostCode']
    abn.score = abnResponse['Score']
    abn.state = abnResponse['State']
  }
  business.abn = abn

  const highlights: Highlight[] = []
  if (businessResponse['highlights']) {
    const highlightsResponse = businessResponse['highlights']
    for (const key of Object.keys(highlightsResponse)) {
      const item = highlightsResponse[key]
      highlights.push({
        sequence: item['sequence'],
        clipId: item['clip-id'],
      } as Highlight)
    }
  }
  business.highlights = highlights

  const rates = {} as Rate
  if (businessResponse.rates) {
    const ratesResponse = businessResponse.rates
    rates.request_on_quotes = ratesResponse['request-on-quotes']
    rates.emergency_fee = ratesResponse['emergency-fee']
    rates.hourly_rate = ratesResponse['hourly-rate']
    rates.call_out_fee = ratesResponse['call-out-fee']

    rates.requestOnQuotes = ratesResponse['request-on-quotes']
    rates.emergencyFee = ratesResponse['emergency-fee']
    rates.hourlyRate = ratesResponse['hourly-rate']
    rates.callOutFee = ratesResponse['call-out-fee']
  }
  business.rates = rates

  const serviceAreas: ServiceArea[] = []
  if (businessResponse['service-areas']) {
    for (const [key, value] of Object.entries(
      businessResponse['service-areas'] ? businessResponse['service-areas'] : {}
    )) {
      const data = {} as ServiceArea
      data.area_name = value['area-name']
      data.geohash_values = value['geohash-values']
      data.image_url = value['image-url']
      data.pictol_cordinates = value['pictol-cordinates']
      data.post_codes = value['post-codes']
      data.suburbs = value.suburbs
      data.areaName = value['area-name']
      data.geohashValues = value['geohash-values']
      data.imageUrl = value['image-url']
      data.pictolCordinates = value['pictol-cordinates']
      data.postCodes = value['post-codes']

      serviceAreas.push(data)
    }
  }
  business.serviceAreas = serviceAreas

  return business
}

export function dtoToBusinessLicense(licenseResponse: any) {
  const license = {} as License

  const address = {} as Address
  if (licenseResponse['business-address']) {
    const addressResponse = licenseResponse['business-address']
    address.address = addressResponse.address
    address.postalCode = addressResponse['postal-code']
    address.route = addressResponse.route
    address.state = addressResponse.state
    address.streetNumber = addressResponse['street-number']
    address.subpremise = addressResponse.subpremise
    address.suburb = addressResponse.suburb
    if (addressResponse.cordinates) {
      const cordinatesResponse = addressResponse.cordinates
      address.cordinates = {
        latitude: cordinatesResponse.latitude,
        longitude: cordinatesResponse.longitude,
      } as Coordinate
    }
  }
  license.business_Address = licenseResponse['business-address']?.address
    ? licenseResponse['business-address']?.address
    : ''
  license.businessAddress = address
  license.effectiveUserId = licenseResponse['effective-user-id']
  license.licenceExpired = licenseResponse['lc-expired']
  license.licenceObjectId = licenseResponse['licence-object-id'] || ''
  license.licenceType = licenseResponse['licence-type'] || ''
  license.licenceExpDate = licenseResponse['nsw-license-expiry-date'] || ''
  license.licenceNo = licenseResponse['nsw-tradie-license-number'] || ''
  license.licenceTypeNsw = licenseResponse['nsw-tradie-license-type'] || ''
  license.licenceValidity = licenseResponse['nsw-tradie-license-validity']
  license.licenceIsValid =
    licenseResponse['nsw-tradie-license-validity'] === 'valid' &&
    (licenseResponse['lc-expired'] ? licenseResponse['lc-expired'] : false)
  license.licenseeName = licenseResponse['nsw-tradie-licensee']

  const tradeNames = Util.getObjectArrayValuesOfKey(
    Util.replaceUndefinedWithDefault(licenseResponse['registered-trades'], []),
    'trade-name'
  )
  license.licenceRegisteredTrades = tradeNames
  license.licenceRegisteredTradesNames = tradeNames.join(',')

  return Util.removeUndefinedProps(license)
}

export function dtoToBusinessMember(response: any) {
  const member = {} as Member

  member.effectiveUserId = response['effective-user-id']
  member.firstName = response?.['first-name'] || ''
  member.lastName = response?.['last-name'] || ''
  member.notificationId = response['notification-id']
  member.notificationStatus = response['notification-status']
  member.profilePic = response?.['profile-pic'] || ''
  member.roleType = response['role-type']
  member.status = response['status']
  member.userId = response['user-id']
  member.businessTrades = response['business-trades']
  member.trades = response['trades']
  member.roleTemplateId = response['role-template-id']
  member.roleTemplateDisplayName = response['role-template-display-name']
  member.mobile = response['mobile']
  member.email = response['email']
  member.inviteId = response['invite-id']
  member.businessId = response['business-id']
  member.invitationType = response['invitation-type']
  member.requestSource =
    response['requested-source'] || response['invitation-type']
  return member
}

//Profile completion

const fetchBusinessProfileCompletion = async (businessId: string) => {
  return await api.get(`/business/${businessId}/profile-completion`)
}

export const useFetchBusinessProfileCompletion = (
  businessId: string,
  enabled = true
) => {
  return useQuery(
    [QueryKeys.BUSINESS_PROFILE_COMPLETION, businessId, useActiveOrgId()],
    () => fetchBusinessProfileCompletion(businessId),
    {
      enabled,
      select: (data) => {
        const completion = data?.data
        if (completion) {
          return dtoBusinessProfileCompletion(completion)
        }
        return undefined
      },
    }
  )
}

const fetchVerifyNSWLicenseNumberAndGetDetails = async (
  licenceNumber: string
) => {
  return await api.get(`/licence/nsw-licence-verification/${licenceNumber}`)
}

export const useFetchVerifyNSWLicenseNumberAndGetDetails = (
  licenceNumber: string,
  enabled = false
) => {
  return useQuery(
    [QueryKeys.LICENCE_NUMBER, licenceNumber, useActiveOrgId()],
    () => fetchVerifyNSWLicenseNumberAndGetDetails(licenceNumber),
    {
      enabled,
      select: (data) => {
        const licenceData = data?.data?.['nsw-rego']
        if (licenceData) {
          return licenceData
        }
        return undefined
      },
    }
  )
}

async function fetchVerifyNSWLicenseByScanIdAndGetDetails(scannedId: string) {
  return await api.get(`/licence/verify-scanned-licence/${scannedId}`)
}

export const useFetchVerifyNSWLicenseByScanIdAndGetDetails = (
  scannedId: string,
  enabled = false
) => {
  return useQuery(
    [QueryKeys.LICENCE_SCANNED_ID, scannedId, useActiveOrgId()],
    () => fetchVerifyNSWLicenseByScanIdAndGetDetails(scannedId),
    {
      enabled,
      select: (data) => {
        const licenceData = data?.data?.['nsw-rego']
        if (licenceData) {
          return licenceData
        }
        return undefined
      },
    }
  )
}

export function dtoBusinessProfileCompletion(response: any) {
  const businessCompletion = {} as BusinessProfileCompletion
  businessCompletion['percentage'] = response['completion-percentage']

  const incompleteFields: BusinessProfileInCompletedFields[] = []

  for (const obj of response['incomplete-fields']) {
    incompleteFields.push({
      field: obj['field'],
      displayName: obj['display-name'],
      priority: obj['priority'],
    })
  }

  businessCompletion['incompleteFields'] = incompleteFields

  return businessCompletion
}

export function businessServiceAreaToDto(obj: ServiceArea) {
  const createObjdata = {
    'area-name': obj.areaName,
    'geohash-values': obj.geohashValues,
    'image-url': obj.imageUrl,
    'pictol-cordinates': obj.pictolCordinates,
    'post-codes': obj.postCodes,
    suburbs: obj.suburbs,
  }

  return Util.removeUndefinedProps(createObjdata)
}

export function businessServiceAbnToDto(obj: ABN) {
  const createObjdata = {
    IsCurrent: obj.isCurrent,
    Score: obj.score,
    State: obj.state,
    NameType: obj.nameType,
    Abn: obj.abn,
    AbnStatus: obj.abnStatus,
    Postcode: obj.postcode,
    Name: obj.name,
  }

  return Util.removeUndefinedProps(createObjdata)
}

/*====================== Fetch Business ================================*/
const validateClaimBusiness = async (businessHandle: string) => {
  return await apiWithoutAuth.get(`/business/validateclaim/${businessHandle}`)
}

export const useValidateClaimBusiness = (
  businessHandle: string,
  enabled = true,
  onErrorCallback: (error: ServerError) => void
) => {
  return useQuery(
    [QueryKeys.UNCLAIMED_BUSINESS, businessHandle],
    () => validateClaimBusiness(businessHandle),
    {
      enabled,
      select: (data) => {
        const business = data?.data?.business
        const user = business?.['prospective-owner'] || {}
        return {
          businessId: business['business-id'],
          businessName: business['business-name'],
          businessLogo: business['business-logo'],
          businessLogoURL: business['business-logo-url'],
          firstName: user?.['first-name'] || '',
          lastName: user?.['last-name'] || '',
          email: user?.['email'] || '',
          phoneNumber: user?.['phone-number'] || '',
        } as UnClaimedBusinessInfo
      },
      onError: (error: any) => {
        onErrorCallback(error?.response?.data as ServerError)
      },
    }
  )
}

const getUnclaimedBusinessDetails = async (phoneNumber: string) => {
  return await apiWithoutAuth.get(
    `/business/validateclaimbyphone/${phoneNumber}`
  )
}

export const useGetUnclaimedBusinessDetails = (
  phoneNumber: string,
  enabled: true
) => {
  return useQuery(
    [QueryKeys.CHECK_UNCLAIMED_BUSINESS_EXIST, phoneNumber],
    () => getUnclaimedBusinessDetails(phoneNumber),
    {
      enabled,
      select: (data) => {
        const businessData = data?.data
        if (businessData) {
          const tradeObjectKey = businessData.business['business-trades']
            ? Object.keys(businessData?.business?.['business-trades'])
            : null
          return {
            businessName: businessData.business['business-name'],
            mobileNumber: businessData.business['phone-number'],
            businessId: businessData.business['business-id'],
            email: businessData.business['prospective-owner'].email,
            firstName: businessData.business['prospective-owner']['first-name'],
            lastName: businessData.business['prospective-owner']['last-name'],
            trade: tradeObjectKey
              ? businessData.business['business-trades']?.[tradeObjectKey[0]]
              : null,
            logoS3Key: businessData.business['business-logo'],
            businessLogo: businessData.business['business-logo-url'],
            handle: businessData.business.handle,
          } as unclaimedBusinessDataProps
        }
        return undefined
      },
      onError: (error: any) => {},
    }
  )
}

const fetchSuppliersAndPartners = async () => {
  return await api.get(`/supplier`)
}

export const useFetchSuppliersAndPartners = (enabled = true) => {
  return useQuery(
    [QueryKeys.SUPPLIERS_DETAILS, useActiveOrgId()],
    () => fetchSuppliersAndPartners(),
    {
      enabled,
      select: (data) => {
        const suppliers: Supplier[] = []
        const SupplierList = data?.data?.suppliers
        if (SupplierList) {
          for (const value of SupplierList) {
            const supplier: Supplier = {} as Supplier
            supplier.supplierId = value['supplier-id']
            supplier.suppliername = value['supplier-name']
            supplier.supplierLogo = value['supplier-logo-url']
            supplier.supplierUrl = value['supplier-url']
            supplier.supplierCoverimage = value['supplier-cover-image-url']
            supplier.services = value['services']
            suppliers.push(supplier)
          }
          return suppliers
        }
        return []
      },
      onError: (error: any) => {},
    }
  )
}

export const useFetchBusinessAccountStorage = (
  businessId: string,
  enabled = true
) => {
  return useQuery(
    [QueryKeys.BUSINESS_ACCOUNT_STORAGE, businessId],
    () => fetchBusinessAccountStorage(businessId),
    {
      enabled,
      initialData: undefined,
      select: (data) => {
        const accountStorageData = data?.data || {}
        return dtoToBusinessAccountStorage(accountStorageData)
      },
      onError: (error: any) => {},
    }
  )
}
