import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import Constants from './Constants'

const app = initializeApp(Constants.FIREBASE_CONFIG)

const fireStoreDb = getFirestore(app)
const firebaseAuth = getAuth(app)

export { fireStoreDb, firebaseAuth }
