import { View } from 'react-native'
import {
  AppColors,
  Spacer,
  Icon,
  ComponentOptions,
} from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'

const { styles } = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
  },
})

type ScrollButtonsProps = {
  hasPrevPage: boolean
  hasNextPage: boolean
  onPressPrev: () => void
  onPressNext: () => void
  enableButtonColor?: string
  disableButtonColor?: string
}

const ScrollButtons = (props: ScrollButtonsProps) => {
  const {
    hasPrevPage,
    hasNextPage,
    onPressPrev,
    onPressNext,
    enableButtonColor: passedEnableButtonColor,
    disableButtonColor: passedDisableButtonColor,
  } = props

  const enableButtonColor = passedEnableButtonColor
    ? passedEnableButtonColor
    : AppColors.common.white

  const disableButtonColor = passedDisableButtonColor
    ? passedDisableButtonColor
    : AppColors.common.subtitleText
  return (
    <View style={styles.containerStyle}>
      <Icon
        name={'ChevronLeft'}
        fill={hasPrevPage ? enableButtonColor : disableButtonColor}
        onPress={hasPrevPage ? onPressPrev : undefined}
        size={12}
      />
      <Spacer isVertical size={ComponentOptions.SPACES.SMALL} />
      <Icon
        name={'ChevronRight'}
        fill={hasNextPage ? enableButtonColor : disableButtonColor}
        onPress={hasNextPage ? onPressNext : undefined}
        size={12}
      />
    </View>
  )
}

export default ScrollButtons
