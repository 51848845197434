import { useInfiniteQuery, useQuery } from 'react-query'
import { Constants, QueryKeys, getAxios, Util } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import {
  Message,
  Metadata,
  Media,
  QuickResponse,
  QuickResponseGroup,
  MessageInfo,
  SentStatus,
} from '../../types/Message'

const api = getAxios(true)

interface PageParam {
  ascending: boolean
  startKey: string
  options: string
  pivotMessageId: string
  includePivot: boolean
}

const fetchMessages = async (
  threadId: string,
  pageParam: PageParam,
  pivotMessageId?: string
) => {
  const messageFetchData: PageParam = {
    ascending: !!pivotMessageId,
    startKey: '',
    options: pivotMessageId ? 'pivot-by-message' : '',
    pivotMessageId: pivotMessageId || '',
    includePivot: !!pivotMessageId,
  }
  const params = pageParam || messageFetchData
  const pageSize = Constants.PAGE_SIZES.MESSAGE_FETCH_BATCH_SIZE
  return await api.get(
    `/threads/${threadId}/messages?page-size=${pageSize}&start-key=${params.startKey}&ascending=${params.ascending}&pivot-message-id=${params.pivotMessageId}&options=${params.options}&include-pivot=${params.includePivot}`
  )
}

export const useFetchMessages = (
  threadId: string,
  pivotMessageId?: string,
  enabled = true,
  onSuccessFirstPivotMessageLoad?: () => void
) => {
  const queryKey = pivotMessageId
    ? [QueryKeys.MESSAGES, threadId, pivotMessageId, useActiveOrgId()]
    : [QueryKeys.MESSAGES, threadId, useActiveOrgId()]
  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) => {
      return fetchMessages(threadId, pageParam, pivotMessageId)
    },
    {
      enabled,
      onSuccess: (data) => {
        if (
          pivotMessageId &&
          (data?.pages || []).length === 1 &&
          onSuccessFirstPivotMessageLoad
        ) {
          onSuccessFirstPivotMessageLoad()
        }
      },
      getNextPageParam: (lastPage) => {
        //called when loading earlier messages
        const startKey = lastPage?.data?.metadata?.start_key
        const messages = lastPage?.data?.messages || []
        const oldestMessageId = messages[messages.length - 1]?.['message-id']
        if (
          (!pivotMessageId && startKey) ||
          (pivotMessageId && messages.length > 0)
        ) {
          return {
            ascending: false,
            startKey: pivotMessageId ? '' : startKey,
            options: pivotMessageId ? 'pivot-by-message' : '',
            pivotMessageId: pivotMessageId ? oldestMessageId : '',
            includePivot: false,
          } as PageParam
        } else {
          return undefined
        }
      },
      getPreviousPageParam: (lastPage, allPages) => {
        // called when loading latest messages from the pivot message
        const page = allPages[0]
        const messages = page?.data?.messages || []
        const latestMessageId = messages[0]?.['message-id']
        if (pivotMessageId && messages.length > 0) {
          return {
            ascending: true,
            startKey: '',
            options: pivotMessageId ? 'pivot-by-message' : '',
            pivotMessageId: pivotMessageId ? latestMessageId : '',
            includePivot: false,
          }
        } else {
          return undefined
        }
      },
      retry: false,
    }
  )
}

export function dtoToMessage(messageResponse: any): Message {
  const message = {} as Message
  message.createdTime = messageResponse['gt-created-time']
  message.updatedTime = messageResponse['gt-updated-time']
  message.appIsDeleting = messageResponse['app-is-deleting'] || false
  message.appIsMessageGroupingNotEnabled =
    messageResponse['app-is-message-grouping-not-enabled'] || false
  message.messageGroupId = messageResponse['message-group-id']
  message.messageGroupSize = messageResponse['message-group-size']
  message.messageGroupIndex = messageResponse['message-group-index']
  message.deliveryStatus = messageResponse['delivery-status']
  message.individualDelivery = messageResponse['individual-delivery']
  message.message = Util.replaceUndefinedWithDefault(
    messageResponse['message'],
    ''
  )
  ;(message.appSentStatus =
    messageResponse['app-sent-status'] || SentStatus.sent),
    (message.messageId = messageResponse['message-id'])
  message.messageSentTime = messageResponse['message-sent-time']
  message.messageEditedTime = messageResponse['message-edited-time']
  message.messageType = messageResponse['message-type']
  message.orgId = messageResponse['org-id']
  message.parentMessage = messageResponse['parent-message']
    ? dtoToMessage(messageResponse['parent-message'])
    : undefined
  message.parentMessageId = Util.replaceUndefinedWithDefault(
    messageResponse['parent-message-id'],
    ''
  )
  message.participants = messageResponse['participants']
  message.postedBy = messageResponse['posted-by']
  message.previousMessageId = messageResponse['previous-message-id']
  message.readInfo = messageResponse['read-info']
  message.sequence = messageResponse['sequence']
  message.showInfo = {}
  message.threadId = messageResponse['thread-id']
  message.threadType = messageResponse['thread-type']
  message.userId = messageResponse['user-id']
  message.appIsSelected = messageResponse['app-is-loading']
  message.appLoadingMessage = messageResponse['app-loading-message']
  message.appTotalUploadingProgress =
    messageResponse['app-total-uploading-progress']
  message.groupInviteMessageType = messageResponse['group-invite-message-type']
  message.invitedBy = messageResponse['invited-by']
  message.metadata = {} as Metadata

  const quickResponses: QuickResponse[] = []
  const quickResponseObj = messageResponse['quick-responses'] || []
  for (const quickReaction of quickResponseObj) {
    quickResponses.push({
      messageId: quickReaction['message-id'],
      orgId: quickReaction['org-id'],
      quickResponse: quickReaction['quick-response'],
      respondedTime: quickReaction['responded-time'],
      responseBy: quickReaction['response-by'],
      userId: quickReaction['user-id'],
    })
  }

  const quickResponseGroupObj: {
    [key: string]: { emoji: string; count: number }
  } = {}

  quickResponses.forEach((v) => {
    const count = (quickResponseGroupObj?.[v.quickResponse]?.count || 0) + 1
    quickResponseGroupObj[v.quickResponse] = { count, emoji: v.quickResponse }
  })

  message.quickResponses = quickResponses
  message.quickResponsesGroup = Object.values(
    quickResponseGroupObj
  ) as unknown as QuickResponseGroup[]
  message.source = messageResponse['source']
  message.system = messageResponse['system']
  message.isForwarded = messageResponse['forwarded-message']
  message.noForwardIndicator = messageResponse['no-forward-indicator']

  if (!!messageResponse['metadata']) {
    const metadata = messageResponse['metadata']
    message.metadata.deletedMessage = metadata['deleted-message']
    message.metadata.media = []
    if (
      metadata['media'] &&
      metadata['media'] !== Constants.DYNAMODB_NONE_CHARACTER
    ) {
      message.metadata.media = metadata['media'].map(
        (m: any) =>
          ({
            key: m['key'],
            type: m['type'],
            clipId: m['clip-id'],
            fileName: m['file-name'],
            path: m['path'],
            businessId: m['business-id'],
            businessName: m['business-name'],
            businessLogo: m['business-logo'],
            businessTrades: m['business-trades'],
            handle: m['handle'],
            thumbnail: m['thumbnail'],
            ...(m['type'] === Constants.MESSAGE_MEDIA_TYPE.TODO_LIST && {
              todolistId: m['todolist-id'],
            }),
          } as Media)
      )
    }
  }

  if (!!messageResponse['individual-delivery']) {
    for (const [key, val] of Object.entries(
      messageResponse['individual-delivery']
    )) {
      message.individualDelivery[key] = Boolean(val)
    }
  }

  if (!!messageResponse['read-info']) {
    for (const [key, val] of Object.entries(messageResponse['read-info'])) {
      message.readInfo[key] = Boolean(val)
    }
  }

  if (!!messageResponse['show-info']) {
    for (const [key, val] of Object.entries(messageResponse['show-info'])) {
      message.showInfo[key] = Boolean(val)
    }
  }
  if (!!messageResponse['user']) {
    const userResponse = messageResponse['user']
    message.user = {
      userId: userResponse['user-id'],
      affiliationType: userResponse['affiliation-type'],
      businessId: userResponse['business-id'],
      businessLogo: userResponse['business-logo'],
      businessName: userResponse['business-name'],
      effectiveUserId: userResponse['effective-user-id'],
      firstName: userResponse['first-name'],
      lastName: userResponse['last-name'],
      profilePic: userResponse['profile-pic'],
      roleTemplateDisplayName: userResponse['role-template-display-name'],
      roleType: userResponse['role-type'],
      participantStatus: userResponse['participant-status'],
      email: userResponse['email'],
      name: userResponse['name'],
    }
  }
  return message
}

const fetchMessageDeliveryInfo = async (messageId: string) => {
  return await api.get(`/messages/${messageId}/delivery-info`)
}

export const useFetchMessageDeliveryInfo = (
  messageId: string,
  enabled = true
) => {
  return useQuery(
    [QueryKeys.DELIVERY_INFO, messageId, useActiveOrgId()],
    () => fetchMessageDeliveryInfo(messageId),
    {
      enabled,
      select: (data) => {
        const output: MessageInfo[] = []
        if (!!data?.data?.['message-delivery-info']) {
          const arr = data.data['message-delivery-info']
          if (arr instanceof Array) {
            arr.forEach((item) => {
              const val = dtoToMessageInfo(item)
              output.push(val)
            })
          }
        }
        return output
      },
    }
  )
}

function dtoToMessageInfo(response: any): MessageInfo {
  const item = {
    effectiveUserId: response['effective-user-id'],
    gtCreatedTime: response['gt-created-time'],
    gtUpdatedTime: response['gt-updated-time'],
    messageId: response['message-id'],
    readInfo: response['read-info'],
    readTime: response['read-time'],
    sentInfo: response['sent-info'],
    sentTime: response['sent-time'],
    showInfo: response['show-info'],
    threadFilter: response['thread-filter'],
    threadId: response['thread-id'],
  } as MessageInfo
  return item
}
