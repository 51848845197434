import { useEffect, useState } from 'react'
import StyleSheet from 'react-native-media-query'
import ClipCentralPreview from '../../components/clipCentral/ClipCentralPreview'
import ClipPreviewNotFoundOrLoading from '../../components/clipCentral/ClipPreviewNotFound'
import { useFetchClip } from '../../hooks/clip/useFetchClip'
import MainContent from '../../layout/MainContentV2'
import NavigationKeys from '../../navigation/NavigationKeys'
import { useNavigate } from 'react-router-dom'
import {
  ClipCentralButtonActionTypes,
  ClipCentralRightPanelTypes,
  ClipDeleteProgress,
  ClipLayout,
} from '../../types/ClipCentral'
import ClipCentralRightPanel from './ClipCentralRightPanel'
import { useActiveEffectiveUserId } from '../../context/UserContext'

const { styles } = StyleSheet.create({
  contentWrapperStyle: {
    flex: 1,
  },
})

type ClipContentProps = {
  clipId?: string
  clipDeletingProgress?: ClipDeleteProgress
  deletedClipId?: string
  onPressClose: () => void
}

const ClipContent = (props: ClipContentProps) => {
  const { clipId, onPressClose, clipDeletingProgress, deletedClipId } = props

  const businessEffectiveUserId = useActiveEffectiveUserId()

  const { data, isLoading } = useFetchClip(clipId || '', !!clipId)
  const [activeRightScreen, setActiveRightScreen] =
    useState<ClipCentralRightPanelTypes | null>(null)

  const [currentLayout, setCurrentLayout] = useState<ClipLayout>(
    ClipLayout.slides
  )

  const navigate = useNavigate()

  useEffect(() => {
    setActiveRightScreen(null)
  }, [clipId])

  const onClose = () => {
    navigate(NavigationKeys.CLIPS_CENTRAL)
  }

  const onPressActionButton = (action: ClipCentralButtonActionTypes) => {
    if (action === ClipCentralButtonActionTypes.AddMore) {
      setActiveRightScreen(ClipCentralRightPanelTypes.AddSlides)
    } else if (action === ClipCentralButtonActionTypes.Send) {
      setActiveRightScreen(ClipCentralRightPanelTypes.Send)
    } else if (action === ClipCentralButtonActionTypes.EditClip) {
      setActiveRightScreen(ClipCentralRightPanelTypes.EditClip)
    } else {
      setActiveRightScreen(null)
    }
  }

  return (
    <MainContent
      onCloseMainContentCallback={onClose}
      rightPanel={
        activeRightScreen !== null && data ? (
          <ClipCentralRightPanel
            activeScreen={activeRightScreen}
            clip={data}
            onClose={() => setActiveRightScreen(null)}
          />
        ) : null
      }
      contentWrapperStyle={styles.contentWrapperStyle}
    >
      {!isLoading && data && (
        <ClipCentralPreview
          businessEffectiveUserId={businessEffectiveUserId}
          clipObj={data}
          onPressClose={onPressClose}
          onPressActionButton={onPressActionButton}
          currentLayout={currentLayout}
          setCurrentLayout={setCurrentLayout}
          clipDeletingProgress={clipDeletingProgress}
          deletedClipId={deletedClipId}
        />
      )}
      {isLoading && (
        <ClipPreviewNotFoundOrLoading
          currentLayout={currentLayout}
          isLoading={true}
        />
      )}
      {!isLoading && !data && (
        <ClipPreviewNotFoundOrLoading
          isLoading={false}
          currentLayout={currentLayout}
        />
      )}
    </MainContent>
  )
}

export default ClipContent
