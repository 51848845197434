import { useMutation } from 'react-query'
import { getAxios } from '../../common'

const api = getAxios(true)

type DeviceInfoObj = {
  'fcm-token': string
}

const sendDeviceInfo = async (deviceInfo: DeviceInfoObj) => {
  return await api.post('/device', deviceInfo)
}

export const useSendDeviceInfo = (
  onSuccess: (resp: any) => void,
  onError: (resp: any) => void
) => {
  return useMutation(sendDeviceInfo, {
    onSuccess,
    onError,
  })
}

const invite = async (invite: any) => {
  return await api.post('/invite', invite)
}
export const useInvite = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any, variables: any) => void
) => {
  return useMutation(invite, {
    onSuccess,
    onError,
  })
}

// Dynamic Link
type DynamicLinkCreate = {
  link: string
}
const createDynamicShortLink = async (req: DynamicLinkCreate) => {
  const url = `${process.env.PROXY_URL}${encodeURIComponent(
    `${process.env.FIREBASE_DYNAMIC_LINK_SHORT_LINK_API}?key=${process.env.FIREBASE_API_KEY}`
  )}`

  return await api.post(url, {
    dynamicLinkInfo: {
      domainUriPrefix: `${process.env.FIREBASE_DYNAMIC_LINK_DOMAIN}`,
      link: req.link,
      androidInfo: {
        androidPackageName: `${process.env.APP_PACKAGE_ANDROID}`,
      },
      iosInfo: {
        iosBundleId: `${process.env.APP_PACKAGE_IOS}`,
      },
    },
    suffix: {
      option: 'SHORT',
    },
  })
}

export const generateDynamicLinkShortUrl = (
  onSuccess: (data: any, variables: DynamicLinkCreate) => void,
  onError: (error: any, variables: DynamicLinkCreate) => void
) => {
  return useMutation(createDynamicShortLink, {
    onSuccess,
    onError,
  })
}
