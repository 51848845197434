import { useNavigate, useParams } from 'react-router-dom'
import { AppColors } from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'
import ClipsCentralSideBar from '../containers/clip/ClipCentralSideBar'
import ClipContent from '../containers/clip/ClipContent'
import Layout from '../layout/Layout'
import StackSidebar from '../layout/StackSideBar'
import { DefaultContentType } from '../types/Common'
import { Clip } from '../types/Files'
import { useQueryClient } from 'react-query'
import { QueryKeys, Util } from '../common'
import { AxiosResponse } from 'axios'
import NavigationKeys from '../navigation/NavigationKeys'
import { HomeSection } from '../types/Home'
import { useState } from 'react'
import { GalleryTabContextProvider } from '../context/GalleryTabContext'
import HomeMainContent from '../containers/home/MainContent'
import React from 'react'

const { styles } = StyleSheet.create({
  shadowStyle: {
    borderColor: AppColors.common.whiteDrop,
    borderEndWidth: '1px',
    '@media (max-width: 800px)': {
      border: 'none',
    },
  },
})

const ClipsCentralPage = () => {
  const { clipId: passedClipId } = useParams()

  const [selection, setSelection] = useState<HomeSection>(HomeSection.default)
  const [clipDeletingProgress, setClipDeletingProgress] = useState<any>()
  const [deletedClipId, setDeletedClipId] = useState<string>('')
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const onPressClip = (clip: Clip) => {
    queryClient.setQueryData([QueryKeys.CLIP, clip.clipId], {
      data: { clip: clip },
    } as AxiosResponse)
    navigate(`${NavigationKeys.CLIPS_CENTRAL}/${clip.clipId}`)
  }

  const onPressClose = () => {
    navigate(`${NavigationKeys.CLIPS_CENTRAL}`)
    setSelection(HomeSection.default)
  }

  return (
    <Layout
      selection={
        passedClipId ||
        (!passedClipId && selection == HomeSection.default
          ? undefined
          : selection)
      }
      defaultContentType={DefaultContentType.clips}
      renderSidebar={
        <StackSidebar
          containerStyle={styles.shadowStyle}
          screenStack={[
            {
              key: 'clips',
              title: 'Clip central',
              screen: () => (
                <ClipsCentralSideBar
                  onPressClip={onPressClip}
                  clipDeletingProgress={(values) => {
                    setClipDeletingProgress(values)
                  }}
                  onSectionChange={(section) => {
                    navigate(`${NavigationKeys.CLIPS_CENTRAL}`)
                    setSelection(section)
                  }}
                  deletedClipId={setDeletedClipId}
                />
              ),
              onBack: () => {
                const redirectTo = Util.getValueFromQueryParameter('redirect')
                if (redirectTo) {
                  navigate(`/${redirectTo}`)
                } else {
                  navigate(NavigationKeys.HOME)
                }
              },
            },
          ]}
        />
      }
      renderContent={
        <React.Fragment>
          {!passedClipId &&
          (selection == HomeSection.CreateEmptyClip ||
            selection == HomeSection.CreateNewClip) ? (
            <GalleryTabContextProvider>
              <HomeMainContent
                type={selection}
                onCloseMainContent={() => setSelection(HomeSection.default)}
              />
            </GalleryTabContextProvider>
          ) : passedClipId ? (
            <ClipContent
              clipId={passedClipId}
              deletedClipId={deletedClipId}
              clipDeletingProgress={clipDeletingProgress}
              onPressClose={onPressClose}
            />
          ) : null}
        </React.Fragment>
      }
    />
  )
}

export default ClipsCentralPage
