/* eslint-disable @typescript-eslint/no-explicit-any */
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Amplify, Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import AuthConfig from './AuthConfig'
import { getAxios } from './AxiosUtil'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import { firebaseAuth } from './FirebaseConfig'

function getCurrentUser(): Promise<CognitoUser> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.currentAuthenticatedUser()
}
function getCurrentSession(): Promise<CognitoUserSession> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.currentSession()
}

async function logoutSession() {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  await Auth.signOut()
}

function loginUser(username: string, password: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.signIn(username, password)
}

function signUpUser(
  username: string,
  password: string,
  uuid: string
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.signUp({
    username,
    password,
    attributes: {
      phone_number: username,
      'custom:uuid': uuid,
    },
  })
}

function signUpUserResendVerificationCode(username: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.resendSignUp(username)
}

function signUpUserConfirm(username: string, code: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.confirmSignUp(username, code)
}

function userUpdateAttributeCognito(
  user: CognitoUser,
  obj: object
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.updateUserAttributes(user, obj)
}

function forgotPassword(username: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.forgotPassword(username)
}

function forgotPasswordSubmit(
  username: string,
  password: string,
  verificationCode: string
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.forgotPasswordSubmit(username, verificationCode, password)
}

async function setSentryUserInfo(userId: string, orgId: string) {
  try {
    const cognitoUserSession = await getCurrentSession()
    const cognitoIdToken = cognitoUserSession?.getIdToken()
    const idTokenPayload = cognitoIdToken?.payload

    const userId = idTokenPayload?.['custom:uuid'] || ''
    const phoneNumber = idTokenPayload?.phone_number || ''

    Sentry.setUser({
      id: userId,
      username: phoneNumber,
    })
    Sentry.setTag('orgId', orgId)
  } catch (error) {
    Sentry.captureException(error, {
      tags: { scenario: 'set_sentry_user_info' },
      level: 'error',
    })
  }
}

function signInToFirebase(
  callBack?: (state: boolean) => void,
  callBackLoading?: (state: boolean) => void
) {
  const a = getAxios()
  a.get('/signup/firebasetoken')
    .then((response) => {
      const { token } = response.data
      signInWithCustomToken(firebaseAuth, token)
        .then((value) => {
          console.log('fbauth', 'User signed in!', value)
          callBack?.(true)
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-custom-token') {
            console.log('fbauth', 'The token you provided is not valid.')
          } else {
            console.log('fbauth', 'Error signing in with custom token', error)
          }
        })
        .finally(() => {
          callBackLoading?.(false)
        })
    })
    .catch((error) => {
      console.log('fbauth', 'error', error)
      callBackLoading?.(false)
    })
}

function signOutFromFirebase(
  callBack?: (state: boolean) => void,
  callBackLoading?: (state: boolean) => void
) {
  signOut(firebaseAuth)
    .then(() => {
      console.log('fbauth', 'User signed out!')
      callBack?.(false)
    })
    .catch((error) => {
      console.log('fbauth', 'error', error)
    })
    .finally(() => {
      callBackLoading?.(false)
    })
}

export default {
  getCurrentUser,
  getCurrentSession,
  logoutSession,
  loginUser,
  signUpUser,
  signUpUserResendVerificationCode,
  signUpUserConfirm,
  userUpdateAttributeCognito,
  forgotPassword,
  forgotPasswordSubmit,
  setSentryUserInfo,
  signInToFirebase,
  signOutFromFirebase,
}
