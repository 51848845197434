export const enum HomeWidgetId {
  NOTIFICATION_PERMISSION = '0000', // notification
  WEATHER = '0001', // weather
  INBOX_BUSINESS = '0002', // inbox-business
  INBOX_WORKER = '0003', // inbox-worker
  INBOX_SEEKER = '0004', // inbox-seeker
  INTRO_BUSINESS = '0005', // intro-business
  INTRO_WORKER = '0006', // intro-worker
  INTRO_SEEKER = '0007', // intro-seeker
  TEAM_MEMBERS = '0008', // team-members (WS owner, worker, pending owner)
  OPTIMIZE_BUSINESS = '0009', // optimize-business
  BUSINESS_STATS = '0010', // business-stats
  YOUR_BUSINESS = '0011', // your-business
  TEAM_CHAT_PROFILE = '0012', // team-chat-profile (WS owner )
  WORKER_PROFILE = '0013', // worker-profile
  INVITE_GOTRADIE = '0014', // invite-gotradie
  FAVOURITE_TRADIE = '0015', // favourite-tradies
  QUICK_SEARCH = '0016', // quick-search-seeker (search by trades)
  JOIN_CONGRATS = '0017', // join-congrats
  INBOX_WORKER_PENDING = '0018', // inbox-worker-pending
  INTRO_WORKER_PENDING = '0019', // intro-worker-pending
  RESET_YOUR_ACCOUNT = '0020', // reset-your-account
  YOUR_PENDING_BUSINESS = '0021', // your-pending-business
  HOLD_ON_TIDE_BUSINESS = '0022', // your-pending-business
  NOTIFICATION_LIST = '0023', // un-read system notifications
  VIEW_WORKER_ONBOARD = '0024', // view-worker-onboarding-screen
  VIEW_OWNER_ONBOARD = '0025', // view-owner-onboarding-screen
  VIEW_CLIPS = '0026', // view-clips
  YOUR_TASKS = '0027', // view-tasks
  All_NOTES = '0028', // view-todolist
}

export type HomeWidget = {
  id: string
  display: boolean
  displayOrder: number
  name: string
}

export const enum HomeSection {
  default = 'default',
  Invite = 'invite',
  NewMember = 'NewMember',
  NewBusiness = 'NewBusiness',
  CreateEmptyClip = 'CreateEmptyClip',
  CreateNewClip = 'CreateNewClip',
  NewTodoList = 'NewTodoList',
}
