import Constants from './Constants'
import { fetchMediaS3Key } from './S3Util'

function filterUserClipPermissions(
  businessEffectiveUserId: string,
  clipPermissionModel: any
) {
  const permissions = {
    edit: false,
    addMore: false,
    send: false,
    share: false,
    delete: false,
  }
  if (
    businessEffectiveUserId &&
    clipPermissionModel &&
    Object.keys(clipPermissionModel).length > 0
  ) {
    const data = clipPermissionModel[businessEffectiveUserId]
    if (data) {
      const userPermissionObject = data.activity
      if (userPermissionObject['*']) {
        const action = userPermissionObject['*'].action
        if (action === Constants.PERMISSION_TYPE.ALLOW) {
          permissions.edit = true
          permissions.addMore = true
          permissions.send = true
          permissions.share = true
          permissions.edit = true
          permissions.delete = true
        }
      } else {
        if (
          userPermissionObject['edit-clip']?.action ===
          Constants.PERMISSION_TYPE.ALLOW
        ) {
          permissions.edit = true
        }
        if (
          userPermissionObject['share-clip']?.action ===
          Constants.PERMISSION_TYPE.ALLOW
        ) {
          permissions.share = true
        }
        if (
          userPermissionObject['send-clip']?.action ===
          Constants.PERMISSION_TYPE.ALLOW
        ) {
          permissions.send = true
        }
        if (
          userPermissionObject['add-more-clip']?.action ===
          Constants.PERMISSION_TYPE.ALLOW
        ) {
          permissions.addMore = true
        }
        if (!userPermissionObject['delete-clip']) {
          permissions.delete = true
        }
      }
    } else {
      // TODO: Need to remove after clip share permission issue is fixed
      permissions.edit = true
      permissions.addMore = true
      permissions.send = true
      permissions.share = true
      permissions.edit = true
      permissions.delete = true
    }
  } else if (
    // When businessEffectiveUserId is not present need to check for wild card permission
    !businessEffectiveUserId &&
    clipPermissionModel &&
    Object.keys(clipPermissionModel).length > 0
  ) {
    const wildCardData = clipPermissionModel['*']
    if (wildCardData) {
      const action = wildCardData.activity?.['*']?.action
      if (action === Constants.PERMISSION_TYPE.ALLOW) {
        permissions.edit = true
        permissions.addMore = true
        permissions.send = true
        permissions.share = true
        permissions.edit = true
        permissions.delete = true
      }
    }
  }
  return permissions
}

export async function fetchMediaUrlByMediaType(media: any) {
  const signedUrl = await fetchMediaS3Key(media['slide-key'])
  return signedUrl
}

export default {
  filterUserClipPermissions,
  fetchMediaUrlByMediaType,
}
