import { Constants, Util } from '../../../common'
import { useFetchClip } from '../../../hooks/clip/useFetchClip'
import StyleSheet from 'react-native-media-query'
import {
  ActivityIndicator,
  SectionList,
  TouchableOpacity,
  View,
} from 'react-native'
import ClipSlideMediaSection from './ClipSlideMediaSection'
import {
  AppColors,
  Text,
  Navigation,
  ComponentOptions,
  Spacer,
  Button,
  Icon,
} from '@gotradie/gt-components'
import {
  Clip,
  GalleryTab,
  GalleyType,
  SelectedSlideGalleryItem,
  SlideType,
  UploadCategory,
  UploadItem,
  UploadStatus,
  AcceptedMediaTypes,
} from '../../../types/Files'
import React, { useState } from 'react'
import {
  APIClipSlide,
  ClipPermissions,
  SlideGalleryItem,
} from '../../../types/ClipCentral'
import FileChooser from '../../FileChooser'
import { useActiveOrgId, useActiveUserId } from '../../../context/UserContext'
import { uploadFileToS3 } from '../../../common/UploadUtil'
import {
  useDeleteSlides,
  useUpdateClip,
} from '../../../hooks/clip/useMutateClip'
import { downloadMediaFile } from '../../../common/S3Util'
import WarningModal from '../../WarningModal'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
    backgroundColor: AppColors.common.tertiary,
  },
  sectionHeaderWrapperStyle: {
    padding: ComponentOptions.SPACES.MEDIUM,
  },
  floatingButtonWrapper: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    bottom: 0,
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
    paddingVertical: ComponentOptions.SPACES.LARGE,
  },
  floatingButtonStyle: {
    width: 50,
    height: 50,
    backgroundColor: AppColors.common.primary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clipPreviewButtonsContainerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
  footerContainerStyle: {
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    backgroundColor: AppColors.common.dark,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 375,
    position: 'absolute',
    bottom: 0,
  },
})

type ClipPreviewButtonProps = {
  onPress: () => void
  iconName: string
  title: string
  isDisabled?: boolean
}
const ClipPreviewButton = (props: ClipPreviewButtonProps) => {
  const { onPress, iconName, title } = props
  return (
    <TouchableOpacity style={styles.clipPreviewButtonsContainerStyle}>
      <Icon
        name={iconName}
        size={22}
        fill={AppColors.common.white}
        stroke={1}
        onPress={onPress}
      />
      <Spacer size={ComponentOptions.SPACES.SMALL} />
      <Text body_small color={AppColors.common.white}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

type GalleryFooterProps = {
  clip: Clip
  item?: SlideGalleryItem
  onPressDelete: () => void
  onPressDownload: () => void
  clipPermissions: ClipPermissions
  isDeleting?: boolean
}
const GalleryFooter = (props: GalleryFooterProps) => {
  const { item, onPressDelete, onPressDownload, clipPermissions, isDeleting } =
    props

  const iconColor = isDeleting ? AppColors.common.grey : AppColors.common.white

  const enableDownload =
    item?.tab === GalleryTab.media && item.galleryType !== GalleyType.audio

  return (
    <View style={styles.footerContainerStyle}>
      {enableDownload && (
        <ClipPreviewButton
          title={`Download`}
          iconName={'Download'}
          onPress={() => onPressDownload()}
        />
      )}

      {isDeleting ? (
        <ActivityIndicator color={iconColor} />
      ) : item?.galleryType === GalleyType.clips && !clipPermissions.delete ? (
        <></>
      ) : (
        <ClipPreviewButton
          title={`Delete`}
          iconName={'Bin'}
          onPress={onPressDelete}
        />
      )}
    </View>
  )
}

type ClipRightPanelSlidesProps = {
  clipId: string
  onPressClose: () => void
  onMediaPress?: (slide: SlideGalleryItem) => void
  editMode?: boolean
  enableGallerySelection?: boolean
  setEnableGallerySelection?: any
  selectedItems?: SelectedSlideGalleryItem[]
  setSelectedItems?: any
}

const ClipRightPanelSlides = (props: ClipRightPanelSlidesProps) => {
  const {
    clipId,
    onPressClose,
    onMediaPress,
    editMode = false,
    enableGallerySelection = false,
    setEnableGallerySelection,
    selectedItems,
    setSelectedItems,
  } = props

  const activeOrgId = useActiveOrgId()
  const userId = useActiveUserId()

  const { data } = useFetchClip(clipId)

  const [uploadingItems, setUploadingItems] = useState<{
    [key: string]: SlideGalleryItem[]
  }>({})

  const [clipSlidesCount, setClipSlidesCount] = useState<number>(0)

  const { mutate: onUpdateClip } = useUpdateClip(onSuccessUpdateClip, (er) => {
    Util.showErrorMessage(er?.message || 'Failed to update clip slides')
  })

  const { mutate: deleteClipSlides, isLoading: isSlidesDeleting } =
    useDeleteSlides(onSuccessDeleteMedia, onErrorDeleteMedia)

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  function onSuccessDeleteMedia() {
    onPressClose()
    setSelectedItems([])
    setShowDeleteModal(false)
    setEnableGallerySelection(false)
    Util.showSuccessMessage('Media deleted')
  }

  function onErrorDeleteMedia(error: any) {
    Util.showErrorMessage(
      error?.response?.data?.message || 'Error on deleting Media'
    )
  }

  const slides = ((data as Clip)?.slides || [])
    .filter((slide) => !slide.isEmptySlide)
    .map(
      (slide) =>
        ({
          ...slide,
          uploadStatus: UploadStatus.uploaded,
          uploadingStatusValue: 0,
          tab: GalleryTab.media,
          galleryType:
            slide.slideType === SlideType.photo
              ? GalleyType.photo
              : GalleyType.video,
          uploadCategory:
            slide.slideType === SlideType.photo
              ? UploadCategory.photo
              : UploadCategory.video,
        } as SlideGalleryItem)
    )
  const groupSlides = Util.filterMediaByDate([
    ...(uploadingItems[GalleryTab.media]
      ? uploadingItems[GalleryTab.media]
      : []),
    ...slides,
  ])

  function onSelectFiles(files: UploadItem[]) {
    if (files.length > 0) {
      const mediaItems = files.filter(
        (item) => item.tab === GalleryTab.media
      ) as SlideGalleryItem[]

      if (mediaItems.length > 0) {
        uploadingItems[GalleryTab.media] = mediaItems
      }
      setUploadingItems({ ...uploadingItems })
      uploadItems(files)
    }
  }

  function onSuccessUpdateClip(data: any, variables: any) {
    const items = variables?.slides || []
    if (items) {
      for (const item of items) {
        onRemoveUploadingItem({
          objectId: item['slide-key'],
          tab: GalleryTab.media,
        } as UploadItem)
      }
    }
  }

  function onRemoveUploadingItem(item: UploadItem) {
    const itemsInTab = uploadingItems[item.tab as string]
    const filtered = itemsInTab.filter(
      (galleryItem: SlideGalleryItem) => galleryItem.objectId !== item.objectId
    )

    uploadingItems[item.tab as string] = filtered
    setUploadingItems({ ...uploadingItems })
  }

  function onRetryUploadItem(item: SlideGalleryItem) {
    item.uploadStatus = UploadStatus.uploading
    item.uploadingStatusValue = 0
    updateUploadingItem(item)
    uploadItems([item])
  }

  function updateUploadingItem(updatedItem: UploadItem) {
    const items = uploadingItems[updatedItem.tab as string]
    if (items) {
      items.map((item: SlideGalleryItem) => {
        if (item.objectId === updatedItem.objectId) {
          return { ...item, ...updatedItem }
        }
        return item
      })
      setUploadingItems({ ...uploadingItems })
    }
  }

  function onUploadProgressChange(progress: number, item: UploadItem) {
    item.uploadingStatusValue = Math.floor(progress)
    updateUploadingItem(item)
  }

  function getClipData(item: UploadItem, isVideo: boolean, index: number) {
    return {
      'slide-type': item.galleryType,
      'slide-key': item.objectId,
      'thumbnail-key': isVideo
        ? Util.getThumbnailKeyFromVideoObjectKey(item.objectId)
        : item.objectId,
      'slide-sequence': index,
      'slide-id': Util.generateUUID(),
      'clip-id': clipId,
      'uploaded-by-user-business-id': activeOrgId,
      'uploaded-by-user-id': userId,
    } as APIClipSlide
  }

  function uploadItems(items: UploadItem[]) {
    const arPromises = []
    for (const item of items) {
      item.uploadedByUserId = userId
      arPromises.push(
        uploadFileToS3(
          item,
          activeOrgId,
          null,
          (key, progress) => onUploadProgressChange(progress, item),
          {
            'media-category': Constants.MEDIA_CATEGORY_TYPES.CLIP,
          }
        )
      )
    }

    if (arPromises.length > 0) {
      Promise.all(arPromises)
        .then((values) => {
          const mediaItems = []
          const uploadedMediaItems: UploadItem[] = []
          for (const value of values) {
            const item = value.item

            if (value.state) {
              item.uploadStatus = UploadStatus.updatingAPI
              item.uploadingStatusValue = 99
              updateUploadingItem(item)

              if (item.tab === GalleryTab.media) {
                mediaItems.push({
                  key: item.objectId,
                  type: item.uploadCategory as UploadCategory,
                })
                uploadedMediaItems.push(item)
              }
            } else {
              item.uploadStatus = UploadStatus.uploadFailed
              item.uploadingStatusValue = 0
              updateUploadingItem(item)
            }
          }

          if (uploadedMediaItems.length > 0) {
            const updatedSlides = [
              ...uploadedMediaItems.map((item, index) =>
                getClipData(
                  item,
                  item.galleryType === GalleyType.video,
                  slides.length + 1 + index
                )
              ),
              ...slides,
            ]

            const clip = {
              'clip-id': clipId,
              'posted-by': userId,
              'empty-clip': false,
              'cover-image': slides[0]?.objectId,
              slides: updatedSlides,
            }

            onUpdateClip(clip)
          }
        })
        .catch((er) => {
          Util.showErrorMessage(er.message)
        })
    }
  }

  const itemSelectAction = (item: any) => {
    if (selectedItems?.some((id) => id.slideId === item.slideId)) {
      const filteredList = selectedItems?.filter(
        (id) => id.slideId !== item.slideId
      )
      setSelectedItems(filteredList)
    } else {
      if (selectedItems) setSelectedItems([...selectedItems, item])
    }
  }

  const selectedItemsReTyped = selectedItems as unknown as SlideGalleryItem[]
  const selectedFirstItem: SlideGalleryItem | undefined =
    selectedItemsReTyped?.find((selectedItem) => selectedItem)
  const clipPermissions = Constants.DEFAULT_CLIP_PERMISSIONS

  const onPressDownload = () => {
    const slides = selectedItemsReTyped as unknown as any
    for (const slide of slides) {
      if (slide?.slideKey) {
        const itemMedia: any = {
          ...slide,
          objectId: slide.slideKey,
          galleryType: slide.uploadCategory,
          extension:
            slide.slideType === UploadCategory.photo
              ? AcceptedMediaTypes.JPEG
              : AcceptedMediaTypes.MP4,
        }
        downloadMediaFile(itemMedia)
      }
    }
    setSelectedItems([])
    setEnableGallerySelection(false)
  }

  return (
    <View style={styles.wrapperStyle}>
      <SectionList
        sections={groupSlides}
        extraData={groupSlides}
        keyExtractor={({ key }, index) => `section${key}${index}`}
        renderItem={({ item }) => {
          return (
            <ClipSlideMediaSection
              items={item.data as SlideGalleryItem[]}
              onRemoveUploadingItem={onRemoveUploadingItem}
              onRetryUpload={onRetryUploadItem}
              setClipSlidesCount={setClipSlidesCount}
              onMediaPress={
                enableGallerySelection ? itemSelectAction : onMediaPress
              }
              enableGallerySelection={enableGallerySelection}
              selectedItems={selectedItemsReTyped}
            />
          )
        }}
        renderSectionHeader={({ section: { title } }) => (
          <View style={[styles.sectionHeaderWrapperStyle]}>
            <Text body color={AppColors.common.white}>
              {Util.getSectionTitleGivenYearMonth(title)}
            </Text>
          </View>
        )}
        showsHorizontalScrollIndicator={false}
        ListHeaderComponent={
          <React.Fragment>
            <Navigation
              title={`Slides`}
              rightIcon={
                <Button
                  buttonColor={
                    editMode
                      ? enableGallerySelection
                        ? AppColors.common.secondery
                        : AppColors.common.subtitleText
                      : AppColors.common.secondery
                  }
                  title={
                    editMode
                      ? enableGallerySelection
                        ? 'Cancel'
                        : 'Select'
                      : 'Close'
                  }
                  type="clear"
                  textProps={editMode ? { body: true } : { body_strong: true }}
                  paddingHorizontal={0}
                  onPress={
                    editMode
                      ? () => {
                          setEnableGallerySelection(
                            (select: boolean) => !select
                          )
                          setSelectedItems([])
                        }
                      : onPressClose
                  }
                />
              }
            />
            <Spacer />
          </React.Fragment>
        }
      />
      {(!selectedItems || (selectedItems && !selectedItems.length)) && (
        <View style={styles.floatingButtonWrapper}>
          <FileChooser
            displayElement={
              <Icon name={'Plus'} size={18} fill={AppColors.common.white} />
            }
            containerStyle={styles.floatingButtonStyle}
            isMultiple={true}
            isFileItem={false}
            onSelectFiles={(items) => {
              clipSlidesCount + items.length <=
              Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
                ? onSelectFiles(items)
                : Util.showErrorMessage(
                    `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
                  )
            }}
            acceptTypes={[GalleyType.photo, GalleyType.video]}
          />
        </View>
      )}
      {selectedItems && selectedItems.length > 0 && (
        <GalleryFooter
          clip={data}
          item={selectedFirstItem}
          isDeleting={isSlidesDeleting}
          onPressDownload={() => onPressDownload()}
          onPressDelete={() => setShowDeleteModal(true)}
          clipPermissions={clipPermissions}
        />
      )}
      <WarningModal
        isVisible={showDeleteModal}
        title={`Remove ${
          selectedItemsReTyped?.length > 1 ? 'slides' : 'slide'
        }?`}
        subTitle={`This will permenantly delete the ${
          selectedItemsReTyped?.length > 1 ? 'slides' : 'slide'
        }`}
        onAccept={() =>
          deleteClipSlides({
            clipId: data.clipId,
            slides: selectedItemsReTyped.map((slide) => slide.slideId),
            threadId: data.threadId,
          })
        }
        onCancel={() => setShowDeleteModal(false)}
        acceptButtonProp={{ buttonColor: AppColors.common.red }}
      />
    </View>
  )
}

export default ClipRightPanelSlides
