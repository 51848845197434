import { useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Text, Spacer, AppColors } from '@gotradie/gt-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Constants, MessageUtils, Util } from '../common'
import NavigationKeys from '../navigation/NavigationKeys'
import { useUserContext } from '../context/UserContext'
import { PageRedirectTypes } from '../types/Common'

const { styles } = StyleSheet.create({
  centerContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

const DefaultRedirectPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { isAuthenticated } = useUserContext()

  useEffect(() => {
    const timer = redirectToPage()
    return () => clearTimeout(timer)
  }, [])

  function redirectToPage() {
    if (isAuthenticated) {
      return setTimeout(() => {
        const redirect = searchParams.get('to')
        const redirectType = searchParams.get('type')
        if (redirect !== null) {
          const url = `/${Util.parseURL(redirect)}`
          navigate(url)
        } else if (redirectType !== null) {
          redirectUsingType(redirectType as PageRedirectTypes, searchParams)
        } else {
          navigate(NavigationKeys.HOME)
        }
      }, Constants.TIMEOUT.WAIT_UNTIL_REDIRECT)
    } else {
      navigate(0)
    }
  }

  function redirectUsingType(
    type: PageRedirectTypes,
    searchParams: URLSearchParams
  ) {
    if (type === PageRedirectTypes.thread) {
      const threadId = searchParams.get('id')
      const threadType = searchParams.get('tt')
      if (!!threadId && !!threadType) {
        const chatUrl = `${
          NavigationKeys.CHAT
        }/${MessageUtils.getThreadFilterFromType(threadType)}/${threadId}`
        navigate(chatUrl)
      } else {
        navigate(NavigationKeys.HOME)
      }
    } else {
      navigate(NavigationKeys.HOME)
    }
  }

  return (
    <View style={styles.centerContainer}>
      <Text
        body
        color={AppColors.common.white}
      >{`Redirecting to the page..`}</Text>
      <Spacer />
      <ActivityIndicator color={AppColors.common.white} />
    </View>
  )
}

export default DefaultRedirectPage
