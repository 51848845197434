import { Image, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  GoTradieLogo,
  Spacer,
  Text,
} from '@gotradie/gt-components'

const { styles } = StyleSheet.create({
  placeholderWrapperStyle: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    paddingHorizontal: ComponentOptions.SPACES.LARGE * 4,
    display: 'flex',
    '@media (max-width: 1024px)': {
      paddingVertical: ComponentOptions.SPACES.LARGE * 4,
      display: 'none',
    },
  },
  imageStyle: {
    width: 545,
    height: 435,
  },
})

const DefaultContent = () => {
  return (
    <View style={styles.placeholderWrapperStyle}>
      <Image
        source={require('../../../images/home-page-graphic.png')}
        style={styles.imageStyle}
        resizeMode={'contain'}
      />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <GoTradieLogo width={252} height={30} />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <Text
        body
        color={AppColors.common.subtitleText}
      >{`Welcome to your HQ, keep track of all your messages, photos, videos, documents and more.`}</Text>
    </View>
  )
}

export default DefaultContent
