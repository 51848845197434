import React, { useEffect, useState } from 'react'
import { Util } from '../common'
import { GalleryItem } from '../types/Files'

type GalleryTabContextType = {
  selectedItems: GalleryItem[]
  setSelectItem: (item: GalleryItem) => void
  clearSelection: () => void
  isSelectModeOn: boolean
  setIsSelectModeOn: (enabled: boolean) => void
  removeFromSelected: (item: GalleryItem) => void
}

type GalleryTabProviderProps = {
  children: React.ReactNode
  isSelectedModeOn?: boolean
}

const GalleryTabContext = React.createContext<
  GalleryTabContextType | undefined
>(undefined)

const GalleryTabContextProvider = ({
  children,
  isSelectedModeOn: passedIsSelectedModeOn,
}: GalleryTabProviderProps) => {
  const [selectedItems, setSelectItems] = useState<GalleryItem[]>([])
  const [isSelectModeOn, setIsSelectModeOn] = useState<boolean>(
    passedIsSelectedModeOn ? passedIsSelectedModeOn : false
  )

  useEffect(() => {
    setSelectItems([])
  }, [isSelectModeOn])

  return (
    <GalleryTabContext.Provider
      value={{
        selectedItems: selectedItems,
        setSelectItem: (item: GalleryItem) => {
          const items = selectedItems.concat([item])
          setSelectItems(Util.removeDuplicatesFromArray(items))
        },
        clearSelection: () => setSelectItems([]),
        isSelectModeOn: isSelectModeOn,
        setIsSelectModeOn: setIsSelectModeOn,
        removeFromSelected: (item: GalleryItem) => {
          const filteredItems = selectedItems.filter(
            (selectedItem) => selectedItem.recordKey !== item.recordKey
          )
          setSelectItems(filteredItems)
        },
      }}
    >
      {children}
    </GalleryTabContext.Provider>
  )
}

const useGalleryTabContext = () => {
  const context = React.useContext(GalleryTabContext)
  if (context === undefined) {
    throw new Error(
      'useGalleryTabContext must be used within a GalleryTabContext'
    )
  }
  return context
}

const useSelectedItems = () => {
  const context = React.useContext(GalleryTabContext)
  return context?.selectedItems
}
const useItemSelected = (item: GalleryItem) => {
  const context = React.useContext(GalleryTabContext)
  return (
    context?.selectedItems.some(
      (selectedItem) => selectedItem.recordKey === item.recordKey
    ) || false
  )
}

export {
  GalleryTabContextProvider,
  useGalleryTabContext,
  useSelectedItems,
  useItemSelected,
}
