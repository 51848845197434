import {
  Thread,
  ParticipantAffiliations,
  ThreadSummary,
  TagList,
  ReadInfo,
  Affiliation,
} from '../types/Thread'
import { TabProps } from '../types/Tab'
import {
  GalleryTab,
  GalleyType,
  ImageUploadFolderType,
  UploadCategory,
  UploadItem,
  UploadStatus,
} from '../types/Files'
import Constants from './Constants'
import Util from './Util'
import {
  ChatMessage,
  ChatUser,
  DeliveryStatus,
  IndividualDelivery,
  Message,
  ReadStatus,
  SeenStatus,
  SentStatus,
  ShowInfo,
} from '../types/Message'
import { dtoToMessage } from '../hooks/message/useFetchMessage'
import { Profile } from '../types/User'
import { QueryClient } from 'react-query'
import moment from 'moment'
import { Globals as GTGlobals, AppColors } from '@gotradie/gt-components'
import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/react'
import QueryKeys from './QueryKeys'

function getInboxTabInfo(filter: string): TabProps {
  let key = ''
  let title = ''
  let icon = ''
  if (filter === Constants.USER_MESSAGE_CHAT_FILTERS.TEAMS) {
    key = Constants.USER_MESSAGE_CHAT_FILTERS.TEAMS
    title = 'Team'
    icon = 'Team'
  } else if (filter === Constants.USER_MESSAGE_CHAT_FILTERS.GENERAL) {
    key = Constants.USER_MESSAGE_CHAT_FILTERS.GENERAL
    title = 'General'
    icon = 'Conversation'
  } else if (filter === Constants.USER_MESSAGE_CHAT_FILTERS.WORKSITES) {
    key = Constants.USER_MESSAGE_CHAT_FILTERS.WORKSITES
    title = 'Worksites'
    icon = 'People'
  } else if (filter === Constants.USER_MESSAGE_CHAT_FILTERS.CLIENTS) {
    key = Constants.USER_MESSAGE_CHAT_FILTERS.CLIENTS
    title = 'Clients'
    icon = 'Person'
  } else if (filter === Constants.USER_MESSAGE_CHAT_FILTERS.THREADS) {
    key = Constants.USER_MESSAGE_CHAT_FILTERS.THREADS
    title = 'Threads'
    icon = 'Bookmark'
  }
  return {
    tabKey: key,
    title,
    icon: { name: icon },
    indicator: true,
  }
}

function getInboxTabItems(chatFilters: string[]): TabProps[] {
  return chatFilters.map((filter) => getInboxTabInfo(filter))
}

function getGalleryTab(key: string): TabProps {
  let title = ''
  let icon = ''
  if (key === GalleryTab.media) {
    icon = 'Picture'
    title = 'Media'
  } else if (key === GalleryTab.docs) {
    icon = 'Copy'
    title = 'Docs'
  } else if (key === GalleryTab.clips) {
    icon = 'Clip'
    title = 'Clips'
  }

  return {
    tabKey: key,
    title,
    icon: { name: icon },
    indicator: false,
    activeTextColor: AppColors.common.white,
    inactiveTextColor: AppColors.common.subtitleText,
  }
}
function getGalleryTabs(filters: string[]): TabProps[] {
  return filters.map((filter) => getGalleryTab(filter))
}

function createThumbnail(file: File, snapTime: number) {
  return new Promise((resolve, reject) => {
    let duration = 0
    if (file.type.match('video')) {
      importFileandPreview(file).then((urlOfFIle) => {
        const video = document.createElement('video')
        const timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
            video.pause()
          }
        }
        video.addEventListener('loadeddata', function () {
          duration = !!video.duration ? video.duration : 0
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
          }
        })
        const snapImage = function () {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas
            .getContext('2d')
            ?.drawImage(video, 0, 0, canvas.width, canvas.height)
          const image = canvas.toDataURL()
          const success = image.length > 100000
          if (success) {
            URL.revokeObjectURL(urlOfFIle)
            resolve(image)
          }
          return success
        }
        video.addEventListener('timeupdate', timeupdate)
        video.preload = 'metadata'
        video.src = urlOfFIle
        // Load video in Safari / IE11
        video.muted = true
        video.playsInline = true
        video.currentTime =
          snapTime > duration ? Math.floor(duration / 2) : snapTime
        video.play()
      })
    } else {
      reject('file not valid')
    }
  })
}

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

function importFileandPreview(file: File, revoke?: boolean): Promise<string> {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL
    const preview = window.URL.createObjectURL(file)
    // remove reference
    if (revoke) {
      window.URL.revokeObjectURL(preview)
    }
    setTimeout(() => {
      resolve(preview)
    }, 100)
  })
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

function formatAMPM(date: Date) {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  return hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm
}

function createThreadLastUpdatedTime(
  updatedTimeString: string,
  withTime = false
) {
  const today = new Date()
  const formattedUpdatedTime = updatedTimeString + 'Z'
  const updatedDateTime = new Date(formattedUpdatedTime)
  let lastUpdated
  const currentYear = today.getFullYear()
  const updatedTimeYear = updatedDateTime.getFullYear()
  const currentMonth = today.getMonth()
  const updatedTimeMonth = updatedDateTime.getMonth()
  const updatedTimeMonthName = monthNames[updatedTimeMonth]
  const currentDay = today.getDate()
  const updatedTimeDay = updatedDateTime.getDate()
  const updatedTimeDayName = weekDays[updatedDateTime.getDay()]
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000).getDate()

  if (updatedTimeYear === currentYear) {
    if (updatedTimeMonth === currentMonth) {
      if (updatedTimeDay === currentDay) {
        lastUpdated = `${withTime ? 'Today, at ' : ''}${formatAMPM(
          updatedDateTime
        )}`
      } else {
        if (updatedTimeDay === yesterday) {
          lastUpdated = `Yesterday ${
            withTime ? `, at ${formatAMPM(updatedDateTime)}` : ``
          }`
        } else {
          if (
            currentDay - updatedTimeDay > 0 &&
            currentDay - updatedTimeDay < 7
          ) {
            // check week old
            return `${updatedTimeDayName}${
              withTime ? `, at ${formatAMPM(updatedDateTime)}` : ``
            }`
          } else {
            return `${updatedTimeMonthName} ${updatedTimeDay}, ${updatedTimeYear} ${
              withTime ? `, at ${formatAMPM(updatedDateTime)}` : ``
            }`
          }
        }
      }
    } else {
      return `${updatedTimeMonthName} ${updatedTimeDay}, ${updatedTimeYear} ${
        withTime ? `, at ${formatAMPM(updatedDateTime)}` : ``
      }`
    }
  } else {
    return `${updatedTimeMonthName} ${updatedTimeDay}, ${updatedTimeYear} ${
      withTime ? `, at ${formatAMPM(updatedDateTime)}` : ``
    }`
  }
  return lastUpdated
}

function getEffectiveUserId(thread?: Thread, userId?: string) {
  if (thread && userId) {
    const affiliations = thread.participantAffiliations
    if (thread?.participants?.includes(userId)) {
      return userId
    } else {
      let effectiveUserId = ''
      // get the participants effective ids
      const effectiveIds = Object.keys(affiliations)
      // go through them find the current user's one
      effectiveIds.some((id) => {
        if (userId === affiliations[id].userId) {
          effectiveUserId = id
          return true
        }
        return false
      })
      return effectiveUserId
    }
  } else {
    return ''
  }
}

function getGeneralThreadTitle(thread: Thread) {
  return thread.groupName
}

function createTeamChatGroupName(thread: Thread, currentUserId: string) {
  if (thread.overrideGroupName) {
    return thread.groupName
  } else {
    let teamChatGroupName = ''
    const participantAffiliations = thread.participantAffiliations
    const affIds = Object.keys(participantAffiliations)
    if (affIds.length === 2) {
      affIds.forEach((affId) => {
        const aff = participantAffiliations[affId]
        if (aff.userId !== currentUserId) {
          teamChatGroupName = `${aff.firstName} ${aff.lastName}`
        }
      })
    } else {
      affIds.forEach((affId) => {
        const aff = participantAffiliations[affId]
        teamChatGroupName = teamChatGroupName + `${aff.firstName}, `
      })
      teamChatGroupName = teamChatGroupName.replace(/,(\s+)?$/, '')
    }
    return teamChatGroupName
  }
}

function getClientChatThreadTitle(thread: Thread, currentUserId: string) {
  const participantAffiliations = thread.participantAffiliations
  const affiliations = Object.values(participantAffiliations)
  const otherParticipant = affiliations.find(
    (aff) => aff.userId !== currentUserId
  )
  return otherParticipant?.firstName
    ? `${otherParticipant.firstName} ${otherParticipant.lastName}`
    : otherParticipant?.email
}

function getThreadTitle(thread?: Thread, currentUserId?: string) {
  if (thread && currentUserId) {
    const threadType = thread.threadType
    if (threadType === Constants.THREAD_TYPE.GENERAL) {
      return getGeneralThreadTitle(thread)
    } else if (threadType === Constants.THREAD_TYPE.TEAMS) {
      return createTeamChatGroupName(thread, currentUserId)
    } else if (threadType === Constants.THREAD_TYPE.CLIENTS) {
      return getClientChatThreadTitle(thread, currentUserId)
    } else {
      return ''
    }
  }
  return ''
}

function createChatParticipantProfileImageArray(
  thread: Thread,
  currentUserId: string
) {
  let threadUserArr: any = []
  const participantAffiliations = thread.participantAffiliations
  const keys = Object.keys(participantAffiliations)

  keys.forEach((key) => {
    const aff = participantAffiliations[key]
    if (aff.userId) {
      const obj = {
        user_id: aff.userId,
        image: { s3Key: aff.profilePic },
        altText: GTGlobals.getAcronymText(aff.firstName || aff.email),
        uniqueId: `${aff.userId}`,
      }
      threadUserArr.push(obj)
    }
  })
  if (keys.length === 2) {
    threadUserArr = threadUserArr.filter(
      (item: any) => item.user_id !== currentUserId
    )
  }
  return threadUserArr
}

function createThreadProfilePicArray(thread: Thread, currentUserId: string) {
  const threadType = thread.threadType
  if (
    threadType === Constants.THREAD_TYPE.GENERAL ||
    (threadType === Constants.THREAD_TYPE.TEAMS && thread.groupIcon)
  ) {
    const threadName = getThreadTitle(thread, currentUserId)
    return [
      {
        image: {
          s3Key: thread.groupIcon,
          s3SignedUrl: thread.groupIcon,
        },
        altText: GTGlobals.getAcronymText(threadName),
        uniqueId: thread.threadId,
      },
    ]
  } else {
    return createChatParticipantProfileImageArray(thread, currentUserId)
  }
}

function getAbsoluteUserId(thread?: Thread, effectiveUserId = '') {
  const affiliations = thread?.participantAffiliations
  if (thread?.threadType === Constants.THREAD_TYPE.TEAMS) {
    return affiliations?.[effectiveUserId].userId
  } else {
    // get the participants effective ids
    const affiliation = affiliations?.[effectiveUserId]

    if (affiliation) {
      const type = affiliation.affiliationType
      if (
        type === Constants.THREAD_AFFILIATION_TYPE.BUSINESS ||
        type === Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_USER ||
        type === Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_BUSINESS_OWNER
      ) {
        return affiliation.userId
      } else if (type === Constants.THREAD_AFFILIATION_TYPE.USER) {
        return effectiveUserId
      } else {
        return null
      }
    }
    return null
  }
}

function replaceMentionsInText(
  text: string | undefined = '',
  tagList: TagList[]
) {
  let output = text
  if (tagList === null) {
    return output
  }
  if (output.match(/{{mention-all::}}/)) {
    const mention = '{{mention-all::}}'
    output = output.replace(mention, `@${'everyone'}`)
  }
  const mentions = (output || '').match(
    /({{mention::([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})}})/gm
  )
  if (mentions === null) {
    return output
  }
  mentions.forEach((mention) => {
    if (mention) {
      const ids = mention.match(
        /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/
      )

      if (ids && ids.length > 0) {
        const id = ids[0]
        const mapping = tagList.find((item) => item.id === id)
        if (mapping) {
          output = output.replace(mention, `@${mapping.name}`)
        }
      }
    }
  })
  return output
}

function getTagList(affiliations: ParticipantAffiliations) {
  const tagList: TagList[] = []
  const affIds = Object.keys(affiliations)
  affIds.forEach((affId) => {
    const aff = affiliations[affId]
    const roleTemplateDisplayName = aff?.roleTemplateDisplayName
    let isActive = true
    if (aff.participantStatus) {
      isActive = aff.participantStatus === 'active'
    }
    const newMember = {
      id: affId,
      name: `${aff.firstName} ${aff.lastName}`,
      subtitle: `${roleTemplateDisplayName} @ ${aff.businessName}`,
      profilePic: aff.profilePic,
      isActive: isActive,
    }
    tagList.push(newMember)
  })
  return tagList
}

function getSummaryMessage(
  summary: ThreadSummary,
  affiliations: ParticipantAffiliations,
  isPendingThread = false,
  sendByMySelf = false
) {
  if (isPendingThread) {
    return 'You’ve been invited to a new worksite' // isPendingThread is only for worksite chats
  } else {
    if (summary.imagesCount) {
      const count = summary.imagesCount
      return `📸 ${count} photo${count > 1 ? 's' : ''} ${
        sendByMySelf ? 'sent' : 'attached'
      }`
    } else if (summary.videosCount) {
      const count = summary.videosCount
      return `🎥 ${count} video${count > 1 ? 's' : ''} ${
        sendByMySelf ? 'sent' : 'attached'
      }`
    } else if (summary.documentsCount) {
      const count = summary.documentsCount
      return `📄 ${count} document${count > 1 ? 's' : ''} ${
        sendByMySelf ? 'sent' : 'attached'
      }`
    } else if (summary.audiosCount) {
      const count = summary.audiosCount
      return `🎙 ${count} audio clip${count > 1 ? 's' : ''} ${
        sendByMySelf ? 'sent' : 'attached'
      }`
    } else if (summary.clipsCount) {
      const count = summary.clipsCount
      return `🎬 ${count} tradie clip${count > 1 ? 's' : ''} ${
        sendByMySelf ? 'sent' : 'attached'
      }`
    } else {
      const tagList = getTagList(affiliations)
      const summaryMessage = summary.message
        ? Util.replaceUndefinedWithDefault(summary.message, '').replace(
            /\n/g,
            ' '
          )
        : ''
      return replaceMentionsInText(summaryMessage, tagList)
    }
  }
}

function isUnread(readInfo: ReadInfo, effectiveUserId: string) {
  if (readInfo) {
    return !readInfo[effectiveUserId]
  }
  return true
}

function getGiftedChatMessages(
  messages: Message[],
  thread: Thread | undefined,
  currentUser: Profile
) {
  const myEffectiveUserId = getEffectiveUserId(thread, currentUser.userId)

  const parsedMessages = messages
    .flat()
    .map((m) => dtoToMessage(m))
    .filter((message) => shouldShowMessage(message.showInfo, myEffectiveUserId))

  const groupedMessages = groupMessagesByMessageGroupId(parsedMessages)
  return groupedMessages.map((message) => {
    return formatGiftedChatMessages(
      message,
      thread as Thread,
      myEffectiveUserId
    )
  })
}

function groupMessagesByMessageGroupId(messages: Message[]) {
  const mergedArray: any[] = []
  const mergedIds: string[] = []
  messages.forEach((message) => {
    const groupId = message.messageGroupId
    if (!mergedIds.includes(groupId) || groupId === undefined) {
      mergedArray.push(message)
      if (groupId) {
        mergedIds.push(groupId)
      }
    } else {
      const existingMessage = mergedArray.find((message) => {
        return (
          message.messageGroupId === groupId &&
          (message.message === undefined || message.message === '') &&
          message.appSentStatus === SentStatus.sent &&
          !message.appIsMessageGroupingNotEnabled
        )
      })

      if (
        existingMessage &&
        (message.message === undefined || message.message === '') &&
        message.appSentStatus === SentStatus.sent &&
        !message.appIsMessageGroupingNotEnabled
      ) {
        const keys = new Set(existingMessage.metadata.media.map((m) => m.key))
        const merged = [
          ...existingMessage.metadata.media,
          ...message.metadata?.media.filter((d) => !keys.has(d.key)),
        ]
        existingMessage.metadata.media = merged
      } else {
        mergedArray.push(message)
      }
    }
  })

  return mergedArray
}

function shouldShowMessage(showInfo: ShowInfo, currentUserId: string) {
  if (showInfo) {
    return showInfo[currentUserId]
  } else {
    return false
  }
}

function formatGiftedChatMessages(
  message: Message,
  thread: Thread,
  myEffectiveUserId: string
): ChatMessage {
  const showAcceptedInviteAsSystemMessage =
    !thread.pendingInvitees.includes(myEffectiveUserId)

  const commonData = {} as ChatMessage
  commonData._id = message.messageId
  commonData.text = getStringWithDefault(message.message)
  commonData.createdAt = moment.utc(message.createdTime).local().toDate()
  commonData.messageObj = message
  commonData.seenStatus = getSeenStatus(message.readInfo, myEffectiveUserId)
  commonData.deliveryStatus = getDeliveryStatus(
    message.individualDelivery,
    myEffectiveUserId
  )
  commonData.readStatus = getReadStatus(message.readInfo, myEffectiveUserId)

  commonData.actionType = Constants.GROUP_INVITE_MESSAGE_TYPE.NONE
  commonData.image =
    (message.metadata.media || []).length > 0 ? 'media' : undefined
  commonData.media = message.metadata.media || null
  commonData.isSelected = message.appIsSelected
  commonData.loadingMessage = Boolean(message.appLoadingMessage)
  commonData.sequence = message.sequence
  commonData.show = message.showInfo[myEffectiveUserId] || false
  commonData.appIsMessageGroupingNotEnabled =
    message.appIsMessageGroupingNotEnabled
  commonData.messageGroupId = message.messageGroupId
  commonData.messageGroupIndex = message.messageGroupIndex
  commonData.messageGroupSize = message.messageGroupSize

  if (message.postedBy && thread) {
    const postedByUser = thread.participantAffiliations[message.postedBy]
    const postedByChatUser = postedByUser
      ? getPostedByUserForMessage(postedByUser)
      : ({} as ChatUser)
    if (!!postedByChatUser) {
      commonData.user = postedByChatUser
    }
  }

  return commonData
}

function mapEffectiveIdToUserId(thread: Thread): Map<string, string> {
  const map = new Map()
  const participants = thread.participants

  participants.forEach((participant) => {
    const affiliation = thread.participantAffiliations[participant]
    const affiliationType = affiliation.affiliationType // check affiliation type
    if (affiliationType === Constants.THREAD_AFFILIATION_TYPE.USER) {
      map.set(participant, participant)
    } else if (
      affiliationType === Constants.THREAD_AFFILIATION_TYPE.BUSINESS ||
      affiliationType === Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_USER ||
      affiliationType ===
        Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_BUSINESS_OWNER
    ) {
      map.set(participant, affiliation.userId)
    }
  })
  map.set(Constants.BOT_ID, Constants.BOT_ID)
  return map
}

function findUsersOfThread(
  thread?: Thread,
  skipBot = false
): Map<string, Affiliation> {
  const userMap = new Map()
  try {
    if (thread) {
      const participants = thread.participants

      participants.forEach((participant) => {
        const affiliation = thread.participantAffiliations[participant]
        userMap.set(affiliation.userId, affiliation)
      })
      if (!skipBot) {
        userMap.set(Constants.BOT_ID, {
          firstName: Constants.BOT_NAME,
        } as Affiliation)
      }
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: { message_utils: 'find_users_of_thread' },
      level: 'error',
    })
  }

  return userMap
}

function findActiveUsersOfThread(thread?: Thread): Map<string, Affiliation> {
  const userMap = new Map()
  if (thread) {
    const participants = thread.participants

    participants.forEach((participant) => {
      const affiliation = thread.participantAffiliations[participant]
      userMap.set(participant, affiliation)
    })
  }
  return userMap
}

function getPostedByUserForMessage(user: Affiliation) {
  if (!user) {
    return {} as ChatUser
  }

  let name = `${user.firstName} ${user.lastName}`
  if (name.trim().length == 0) {
    name = user.email || '' // for client chats
  }

  return {
    _id: user.effectiveUserId,
    name,
    avatar: user.profilePic,
    affiliationType: user.affiliationType,
    userId: user.userId,
  } as ChatUser
}

function replaceUndefinedWithDefault(value: string, defaultValue: string) {
  return value === undefined ||
    value === null ||
    value === '' ||
    value === 'NONE' ||
    value === Constants.DYNAMODB_NONE_CHARACTER
    ? defaultValue
    : value
}

function getStringWithDefault(value: any) {
  if (typeof value !== 'string') {
    value = null
  }
  return replaceUndefinedWithDefault(value, '')
}

function getSeenStatus(
  messageReadInfo: ReadInfo,
  myEffectiveUserId: string
): SeenStatus {
  if (!messageReadInfo) {
    return SeenStatus.notAvailable
  }

  const readInfo: ReadInfo = { ...(messageReadInfo as object) } as ReadInfo // copy the read info object
  delete readInfo[myEffectiveUserId] // remove key-value pair for me

  const readInfoValues = Object.values(readInfo)

  const isAllRead = readInfoValues.every((item) => item === true) // check if all the recipients read the message
  if (isAllRead) {
    if (readInfoValues.length === 1) {
      return SeenStatus.seen
    } else {
      return SeenStatus.seenAll
    }
  } else {
    if (readInfoValues.length === 1) {
      // else if there is only one then it's a one to one chat
      return SeenStatus.notAvailable
    }
    const isPartialRead = readInfoValues.some((item) => item === true) // check if only some users read the message
    if (isPartialRead) {
      return SeenStatus.seen
    } else {
      return SeenStatus.notAvailable
    }
  }
}

function isListScrolledToTop(nativeOnScrollEvent: any, fromTop = 80) {
  const { layoutMeasurement, contentOffset, contentSize } = nativeOnScrollEvent
  return (
    contentSize.height - layoutMeasurement.height - fromTop <= contentOffset.y
  )
}

function isListScrolledToBottom(nativeOnScrollEvent: any, fromBottom = 0) {
  const { contentOffset } = nativeOnScrollEvent
  return contentOffset.y <= fromBottom
}

function getNextLocalMessageSequence() {
  return Date.now()
}

function getMessageBubbleBackGroundColor(
  position: string,
  isDeletedMessage: boolean,
  isSentViaEmail: boolean
) {
  if (isDeletedMessage) {
    return AppColors.common.white
  } else {
    if (position === 'right') {
      return AppColors.common.tertiary
    } else if (isSentViaEmail) {
      return AppColors.common.forest
    } else {
      return AppColors.common.concrete
    }
  }
}

function getMessageBubbleTextColor(
  position: string,
  isDeletedMessage: boolean,
  isSentViaEmail: boolean
) {
  if (isDeletedMessage) {
    return AppColors.common.grey
  } else {
    if (position === 'right' || isSentViaEmail) {
      return AppColors.common.white
    } else {
      return AppColors.common.tertiary
    }
  }
}

async function updateQueryCache(
  queryClient: QueryClient,
  queryKey: string[],
  message: any
) {
  const queryData: any = queryClient.getQueriesData(queryKey)

  const pages =
    !!queryData && queryData.length > 0 && queryData[0].length > 1
      ? queryData[0][1]?.pages || undefined
      : undefined

  if (!pages) {
    return
  }

  queryClient.setQueryData(queryKey, (oldQueryData: any) => {
    const pages = oldQueryData?.pages
    const index = findExistingMessageIndexFromCache(
      pages,
      message['message-id']
    )

    if (index) {
      const pageIndex = index[0]
      const messageIndex = index[1]

      const page = pages[pageIndex]
      const messages = [...page.data.messages]

      messages[messageIndex] = { ...messages[messageIndex], ...message }

      const updatedPage = {
        ...page,
        data: {
          ...page.data,
          messages: messages,
        },
      }

      const pagesCopy = [...oldQueryData?.pages]
      pagesCopy[pageIndex] = updatedPage

      return {
        ...oldQueryData,
        pages: pagesCopy,
      }
    } else {
      const page1 = oldQueryData?.pages?.[0]
      const messagesSet1 = page1?.data?.messages
      const updatedMessageSet1 = [message, ...messagesSet1]
      const updatedPage1 = {
        ...page1,
        data: {
          ...page1.data,
          messages: updatedMessageSet1,
        },
      }

      const updatedPages = [...oldQueryData?.pages]
      updatedPages[0] = updatedPage1

      return {
        ...oldQueryData,
        pages: updatedPages,
      }
    }
  })
}

async function removeFromQueryCache(
  queryClient: QueryClient,
  queryKey: string[],
  message: any
) {
  const queryData = queryClient.getQueriesData(queryKey)
  const pages = queryData?.pages
  if (!pages) {
    return
  }
  queryClient.setQueryData(queryKey, (oldQueryData) => {
    const pages = oldQueryData?.pages
    const index = findExistingMessageIndexFromCache(
      pages,
      message['message-id']
    )
    if (index) {
      const pageIndex = index[0]
      const messageIndex = index[1]

      const page = pages[pageIndex]
      const messages = [...page.data.messages]

      delete messages[messageIndex]

      const updatedPage = {
        ...page,
        data: {
          ...page.data,
          messages: messages,
        },
      }

      const pagesCopy = [...oldQueryData?.pages]
      pagesCopy[pageIndex] = updatedPage

      return {
        ...oldQueryData,
        pages: pagesCopy,
      }
    }
  })
}

function shouldUpdateQueryCache(
  queryClient: QueryClient,
  queryKey: string[],
  pivotMessageId: string
) {
  if (!pivotMessageId) {
    return true
  }

  const queryData = queryClient.getQueriesData(queryKey)
  const pages = queryData?.pages
  if (!!pages && pages.length > 0) {
    const hasRetreivedLatest = pages[0].data.messages.length === 0
    return hasRetreivedLatest
  }
  return false
}

function findExistingMessageIndexFromCache(pages, existingMessageId) {
  if (!pages) {
    return null
  }
  let row = -1
  let column = -1
  pages.forEach((page, i) => {
    const messages = page?.data?.messages
    const j = messages.findIndex(
      (message) => message['message-id'] === existingMessageId
    )
    if (j >= 0) {
      row = i
      column = j
    }
  })
  if (row < 0 || column < 0) {
    return null
  } else {
    return [row, column]
  }
}

function getThumbnailKeyFromVideoObjectKey(key) {
  if (key) {
    const sections = key.split('/')
    if (sections.length !== 2) {
      return null
    }
    return `videosthumbnail/${sections[1]}`
  }
  return null
}

function generateMessageUUID(): string {
  return uuidv4()
}

function invalidateNotificationIndicatorQueries(
  queryClient: QueryClient,
  activeOrgId: string
) {
  //invalidate indicator on Home menu & notifications list
  queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST, activeOrgId])
  //invalidate indicator on Chat menu & indicators on chat tabs
  queryClient.invalidateQueries([
    QueryKeys.UNREAD_THREADS_COUNT,
    '',
    activeOrgId,
  ])
  //invalidate threads inside tabs & their indicators
  queryClient.invalidateQueries({
    predicate: (query) =>
      query.queryKey[0] === QueryKeys.THREADS &&
      query.queryKey[2] === activeOrgId,
  })
}

function getThreadMiniProfileSetSubDetailText(
  userDetails: Profile,
  threadAffiliations: ParticipantAffiliations,
  effectiveUserId: string
) {
  const affiliation = threadAffiliations[effectiveUserId]
  if (!affiliation) {
    return undefined
  } else {
    const userBusiness = userDetails.business?.filter(
      (el: { businessId: string }) => affiliation.businessId === el.businessId
    )[0]
    const roleTemplateDisplayName = affiliation?.roleTemplateDisplayName
    const affiliationType = affiliation.affiliationType
    if (
      affiliationType === Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_USER ||
      affiliationType ===
        Constants.THREAD_AFFILIATION_TYPE.PROSPECTIVE_BUSINESS_OWNER
    ) {
      return {
        prefix:
          userDetails.firstName !== '' || userDetails.lastName !== ''
            ? `${userDetails.firstName} ${userDetails.lastName}`
            : userDetails.email,
        business: undefined,
      }
    } else {
      return {
        prefix: `${roleTemplateDisplayName} @ `,
        business: userBusiness,
      }
    }
  }
}

function getIsTeamMember(
  userId: string,
  members: Array<any>[],
  key = 'userId'
) {
  return members.find((member) => member[key] === userId) !== undefined
}

function findMessageFromCacheById(
  queryClient: QueryClient,
  queryKey: string[],
  messageId: string
): Message {
  const queryData: any = queryClient.getQueryData(queryKey)
  let message
  const pages = queryData?.pages || []
  pages.some((page: any) => {
    const messages = page?.data?.messages
    message = messages.find(
      (message: any) => message['message-id'] === messageId
    )
    return !!message
  })
  return message
}

function filterTeamMemberIds(
  affliations: ParticipantAffiliations,
  filterEffectiveId: boolean
) {
  const userIds = <string[]>[]
  const allIds = Object.keys(affliations)
  allIds.forEach((key: string) => {
    if (filterEffectiveId) {
      userIds.push(key)
    } else {
      userIds.push(affliations[key].userId)
    }
  })
  return userIds
}

function findMemberStatus(memberEffId: string, thread: Thread) {
  return thread.pendingInvitees.includes(memberEffId)
    ? Constants.THREAD_MEMBER_STATUS.PENDING
    : Constants.THREAD_MEMBER_STATUS.ACTIVE
}

function createUserDetailsArrayFromAffiliations(
  thread?: Thread,
  currentUser?: Profile,
  isDeleteCurrentUser = false
) {
  if (thread) {
    const groupMembersArray = []
    const myEffectiveUserId = getEffectiveUserId(thread, currentUser?.userId)
    const participantAffiliations = { ...thread.participantAffiliations }

    // remove users not exist in participant array
    const affiliationsKeys = Object.keys(participantAffiliations)

    // remove my self from the array
    if (isDeleteCurrentUser) {
      delete participantAffiliations[myEffectiveUserId]
    }

    affiliationsKeys.forEach((key) => {
      if (!thread.participants.some((user) => user === key)) {
        delete participantAffiliations[key]
      }
    })

    let userEffectiveIds
    if (thread.threadType !== Constants.THREAD_TYPE.TEAMS) {
      userEffectiveIds = Object.keys(participantAffiliations)
    } else {
      userEffectiveIds = filterTeamMemberIds(participantAffiliations, true)
    }
    for (const userEffId of userEffectiveIds) {
      const userProfile = participantAffiliations[userEffId]
      const obj = {
        id: userEffId,
        profile: userProfile,
        type: Constants.THREAD_AFFILIATION_TYPE.USER,
        status: findMemberStatus(userEffId, thread),
      }
      groupMembersArray.push(obj)
    }

    return groupMembersArray
  }
}

function createMemberItemSubTitleText(profile: any, thread?: Thread) {
  const threadType = thread?.threadType
  const roleTemplateDisplayName = profile.roleTemplateDisplayName
  let subtitle = ''
  if (threadType === Constants.THREAD_TYPE.TEAMS) {
    subtitle = roleTemplateDisplayName
  } else if (threadType === Constants.THREAD_TYPE.GENERAL) {
    const businessName = profile.businessName
    subtitle = `${roleTemplateDisplayName} @ ${businessName}`
  }
  return subtitle
}

function checkMemberIsAdmin(item: any, thread: Thread) {
  if (thread.threadType === Constants.THREAD_TYPE.TEAMS) {
    return true
  } else {
    const effectiveUserId = getEffectiveUserId(thread, item.id)
    return thread?.admins.includes(effectiveUserId)
  }
}

async function formatUploadedItems(
  fileList: FileList,
  isFileSelection: boolean,
  imageUploadFolder: ImageUploadFolderType = ImageUploadFolderType.IMAGE
) {
  const items: UploadItem[] = []
  try {
    for (const [, value] of Object.entries(fileList || {})) {
      const file: File = value
      const commonFileAttributes = {
        uploadStatus: UploadStatus.uploading,
        uploadingStatusValue: 0,
        gtCreatedTime: new Date().toISOString(),
        gtUpdatedTime: new Date().toISOString(),
      }

      if (
        file.type === Constants.MIME_TYPES.PNG ||
        file.type === Constants.MIME_TYPES.JPEG ||
        file.type === Constants.MIME_TYPES.JPG ||
        file.type === Constants.MIME_TYPES.HEIC ||
        file.type === Constants.MIME_TYPES.HEIF ||
        file.type === 'image/webp'
      ) {
        const buffer = await file.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))
        const key = `${imageUploadFolder}/${Util.generateUUID()}`
        items.push({
          recordKey: `${GalleyType.photo}_${key}`,
          objectId: key,
          fileName: file.name,
          galleryType: GalleyType.photo,
          file: file,
          uploadingUrl: url,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.photo,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (file.type === 'video/mp4') {
        const buffer = await file.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))
        const key = `videos/${Util.generateUUID()}`

        items.push({
          recordKey: `${GalleyType.video}_${key}`,
          objectId: key,
          fileName: file.name,
          galleryType: GalleyType.video,
          file: file,
          uploadingUrl: url,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.video,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (file.type === 'audio/mpeg') {
        const buffer = await file.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))
        const key = `audios/${Util.generateUUID()}`
        items.push({
          recordKey: `${GalleyType.audio}_${key}`,
          objectId: key,
          fileName: file.name,
          galleryType: GalleyType.audio,
          file: file,
          uploadingUrl: url,
          tab: GalleryTab.media,
          uploadCategory: UploadCategory.audio,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (
        isFileSelection &&
        file.type !==
          ('audio/mpeg' ||
            'video/mp4' ||
            'image/webp' ||
            Constants.MIME_TYPES.PNG ||
            Constants.MIME_TYPES.JPEG ||
            Constants.MIME_TYPES.JPG ||
            Constants.MIME_TYPES.HEIC ||
            Constants.MIME_TYPES.HEIF)
      ) {
        const buffer = await file.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer]))
        const key = `${Util.generateUUID()}`

        items.push({
          recordKey: `${GalleyType.docs}_${key}`,
          objectId: key,
          fileName: file.name,
          galleryType: GalleyType.docs,
          file: file,
          uploadingUrl: url,
          tab: GalleryTab.docs,
          uploadCategory: UploadCategory.document,
          ...commonFileAttributes,
        } as UploadItem)
      } else if (!isFileSelection) {
        throw Error('Trying to upload an unsupported file format.')
      }
    }
  } catch (error) {
    Util.showErrorMessage((error as Error).message)
    Sentry.captureException(error, {
      tags: { message_utils: 'format_uploaded_items' },
      level: 'error',
    })
  }
  return items
}

function getThreadFilterFromType(threadType: string) {
  if (threadType === Constants.THREAD_TYPE.TEAMS) {
    return Constants.USER_MESSAGE_CHAT_FILTERS.TEAMS
  } else if (threadType === Constants.THREAD_TYPE.GENERAL) {
    return Constants.USER_MESSAGE_CHAT_FILTERS.WORKSITES
  } else if (threadType === Constants.THREAD_TYPE.CLIENTS) {
    return Constants.USER_MESSAGE_CHAT_FILTERS.CLIENTS
  } else {
    return ''
  }
}

function checkIsMessageWithMedia(message: Message) {
  return (
    message?.metadata?.media?.filter(
      (e: any) =>
        e.type === Constants.MESSAGE_MEDIA_TYPE.PHOTO ||
        e.type === Constants.MESSAGE_MEDIA_TYPE.VIDEO ||
        e.type === Constants.MESSAGE_MEDIA_TYPE.TODO_LIST
    ).length > 0
  )
}

function markThreadAsReadInQueryCache(
  thread: undefined | Thread,
  myEffectiveUserId: string,
  queryClient: QueryClient,
  activeOrgId: string,
  threadId: string,
  currentUser: undefined | Profile
) {
  if (thread && isUnread(thread?.readInfo, myEffectiveUserId)) {
    let currentThreadFilter: string
    // Update the query where it contains only unready threads
    const queriesData = queryClient.getQueriesData({
      predicate: (query) => {
        return (
          query.queryKey?.[0] === QueryKeys.THREADS &&
          query.queryKey?.[2] === activeOrgId
        )
      },
    })
    queriesData.forEach((queryDataEntry) => {
      const queryKey = queryDataEntry[0]
      if (queryKey[1] === Constants.THREAD_FILTER_OPTIONS.UNREAD_ONLY) {
        const queryData = queryDataEntry[1]
        const threadsInCache = queryData?.data?.threads || []
        const threadsCopy = [...threadsInCache]
        const index = threadsCopy.findIndex(
          (thread) => thread['thread-id'] === threadId
        )
        if (index > -1) {
          threadsCopy.splice(index, 1)
        }
        queryClient.setQueryData(queryKey, (oldQueryData) => {
          const dataCopy = { ...oldQueryData.data }
          dataCopy.threads = threadsCopy
          return {
            ...oldQueryData,
            data: dataCopy,
          }
        })
      }

      // Update query which keeps thread lists for inbox
      if (currentUser.chatFilters.includes(queryKey[1])) {
        const queryData = queryDataEntry[1]
        const pages = queryData?.pages || []
        const pagesCopy = [...pages]

        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
          const page = pages[pageIndex]
          const threadsCopy = [...(page?.data?.threads || [])]

          for (
            let threadIndex = 0;
            threadIndex < threadsCopy.length;
            threadIndex++
          ) {
            const thread = threadsCopy[threadIndex]
            if (thread['thread-id'] === threadId) {
              currentThreadFilter = queryKey[1]
              const threadCopy = { ...thread }
              const readInfo = threadCopy['read-info']
              const readInfoCopy = { ...readInfo }
              if (
                Object.prototype.hasOwnProperty.call(
                  readInfo,
                  myEffectiveUserId
                )
              ) {
                readInfoCopy[myEffectiveUserId] = true
              }
              threadCopy['read-info'] = readInfoCopy
              threadsCopy[threadIndex] = threadCopy
              break
            }
          }
          pagesCopy[pageIndex] = {
            ...page,
            data: {
              ...page.data,
              threads: threadsCopy,
            },
          }
        }
        queryClient.setQueryData(queryKey, (oldQueryData) => {
          return {
            ...oldQueryData,
            pages: pagesCopy,
          }
        })
      }
    })

    // Update query which keeps the counts of each thread filter
    queryClient.setQueryData(
      [QueryKeys.UNREAD_THREADS_COUNT, '', activeOrgId],
      (oldQueryData) => {
        const fullUnreadBreakdown =
          oldQueryData?.data?.['full-unread-breakdown'] || {}
        const fullUnreadBreakdownCopy = { ...fullUnreadBreakdown }
        if (
          Object.prototype.hasOwnProperty.call(
            fullUnreadBreakdownCopy,
            currentThreadFilter
          )
        ) {
          const currentUnreadCount =
            fullUnreadBreakdownCopy[currentThreadFilter]
          if (currentUnreadCount > 0) {
            fullUnreadBreakdownCopy[currentThreadFilter] =
              currentUnreadCount - 1
            return {
              ...oldQueryData,
              data: {
                ...oldQueryData.data,
                'full-unread-breakdown': fullUnreadBreakdownCopy,
              },
            }
          }
        }
        return {
          ...oldQueryData,
        }
      }
    )
  }
}

export function getDeliveryStatus(
  individualDelivery: IndividualDelivery,
  myEffectiveUserId: string
): DeliveryStatus {
  if (!individualDelivery) {
    return DeliveryStatus.notAvailable
  }

  const individualDeliveryCopy = { ...individualDelivery } // copy the individual delivery object
  delete individualDeliveryCopy[myEffectiveUserId] // remove key-value pair for me
  const values = Object.values(individualDeliveryCopy)
  // count true values
  const count = values.reduce((a, b) => a + (b === true ? 1 : 0), 0)
  if (count === values.length) {
    return DeliveryStatus.deliveryAll
  } else if (count >= 1) {
    return DeliveryStatus.delivered
  } else {
    return DeliveryStatus.notAvailable
  }
}

export function getReadStatus(readInfo: ReadInfo, myEffectiveUserId: string) {
  if (!readInfo) {
    return ReadStatus.notAvailable
  }
  const readInfoWithUsers = { ...readInfo } // copy the read info object
  delete readInfoWithUsers[myEffectiveUserId] // remove key-value pair for me

  const readInfoValues = Object.values(readInfoWithUsers)

  const isAllRead = readInfoValues.every((item) => item === true) // check if all the recipients read the message
  if (isAllRead) {
    if (readInfoValues.length === 1) {
      return ReadStatus.read
    } else {
      return ReadStatus.readAll
    }
  } else {
    if (readInfoValues.length === 1) {
      // else if there is only one then it's a one to one chat
      return ReadStatus.notAvailable
    }
    const isPartialRead = readInfoValues.some((item) => item === true) // check if only some users read the message
    if (isPartialRead) {
      return ReadStatus.read
    } else {
      return ReadStatus.notAvailable
    }
  }
}

function getMemberName(profile: Profile) {
  let name = ''
  if (!!profile.firstName || !!profile.lastName) {
    name = `${profile?.firstName || ''} ${profile.lastName || ''}`.trim()
  } else {
    name = `${profile.email || ''}`
  }

  return name
}

export default {
  getDeliveryStatus,
  checkIsMessageWithMedia,
  checkMemberIsAdmin,
  createMemberItemSubTitleText,
  createThreadLastUpdatedTime,
  createThreadProfilePicArray,
  createThumbnail,
  createUserDetailsArrayFromAffiliations,
  dataURLtoFile,
  filterTeamMemberIds,
  findActiveUsersOfThread,
  findMessageFromCacheById,
  findUsersOfThread,
  formatUploadedItems,
  generateMessageUUID,
  getAbsoluteUserId,
  getEffectiveUserId,
  getGalleryTabs,
  getGiftedChatMessages,
  getInboxTabInfo,
  getInboxTabItems,
  getIsTeamMember,
  getMessageBubbleBackGroundColor,
  getMessageBubbleTextColor,
  getNextLocalMessageSequence,
  getSummaryMessage,
  getThreadFilterFromType,
  getThreadMiniProfileSetSubDetailText,
  getThreadTitle,
  getThumbnailKeyFromVideoObjectKey,
  invalidateNotificationIndicatorQueries,
  isListScrolledToBottom,
  isListScrolledToTop,
  isUnread,
  mapEffectiveIdToUserId,
  markThreadAsReadInQueryCache,
  removeFromQueryCache,
  shouldUpdateQueryCache,
  updateQueryCache,
  getMemberName,
}
