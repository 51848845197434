import { GalleryItem, Slide } from './Files'

export enum ClipCentralRightPanelTypes {
  AddSlides,
  EditClip,
  Send,
}

export enum ClipCentralButtonActionTypes {
  AddMore,
  EditClip,
  CopyLink,
  Send,
}

export interface SlideGalleryItem extends Slide, GalleryItem {}

export interface SlideGalleryItemProps {
  RightIcon?: (item: SlideGalleryItem) => React.ReactElement | null
  onPressItem: (item: SlideGalleryItem) => void
  onRemoveUploadingItem?: (item: SlideGalleryItem) => void
  onRetryUpload?: (item: SlideGalleryItem) => void
}

export type ClipDeleteProgress = {
  clipId?: string
  isDeleting?: boolean
  isDeleted?: boolean
}

export type DeleteClipParams = {
  clipId: string
  threadId?: string
}

export type DeleteSlideParams = {
  clipId: string
  slideId: string
  threadId?: string
}

export type DeleteSlidesParams = {
  clipId: string
  slides?: string[]
  threadId?: string
}

export type APIClipSlide = {
  'slide-type': string
  'slide-key': string
  'thumbnail-key': string
  'slide-sequence': number
  'slide-id': string
  'clip-id': string
  'uploaded-by-user-business-id': string
  'uploaded-by-user-id': string
}

export type EmptyClip = {
  isLoading: boolean
  clipId: string
}

export enum ClipLayout {
  highlight,
  slides,
}

export type ClipPermissions = {
  edit: boolean
  addMore: boolean
  send: boolean
  share: boolean
  delete: boolean
}
