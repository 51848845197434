import { Member } from './Business'

export type TagList = {
  id: string
  name: string
  subtitle: string
  profilePic: string
  isActive: boolean
}

export interface Affiliation {
  userId: string
  affiliationType: string
  businessId?: string
  businessLogo?: string
  businessName?: string
  firstName: string
  lastName: string
  participantStatus?: string
  profilePic: string
  roleType: string
  roleTemplateDisplayName: string
  effectiveUserId: string
  email?: string
  phoneNumber?: string
  name?: string
  threadFilters?: string[]
}

export interface Invitee {
  threadType: string
  status: string
}

export interface ParticipantAffiliations {
  [id: string]: Affiliation
}

export interface Invitees {
  [id: string]: Invitee
}

export interface ThreadSummary {
  message: string
  videosCount?: number
  imagesCount?: number
  audiosCount?: number
  documentsCount?: number
  clipsCount?: number
  lastPostedBy: string
  threadType: string
  messageId: string
  messageSentTime: string
}

export interface GroupThreadMetaData {
  groupPrivacy: string
  groupVisibility: string
}

export interface ReadInfo {
  [id: string]: boolean
}

export interface MuteInfo {
  [id: string]: boolean
}

export interface Thread {
  admins: string[]
  createdBy: string
  creatorOrgId: string
  creatorRole: string
  groupName: string
  groupIcon: string
  groupPrivacy: string
  overrideGroupName: boolean
  groupThreadMetaData: GroupThreadMetaData
  gtCreatedTime: string
  gtUpdatedTime: string
  invitees: Invitees
  threadId: string
  participantAffiliations: ParticipantAffiliations
  participants: string[]
  pendingInvitees: string[]
  postedBy: string
  summary: ThreadSummary
  threadType: string
  readInfo?: ReadInfo
  muteInfo?: MuteInfo
}

export interface NewThread {
  threadType: string
  threadMembers?: Member[]
}

export enum ShareStatus {
  notSend,
  sending,
  sent,
}

export interface ThreadShareItem extends Thread {
  shareStatus: ShareStatus
  searchText: string
}

export interface UnreadThreadCounts {
  clients: number
  teams: number
  worksites: number
}

export interface MentionUser {
  id: string
  name?: string
  title?: string
  subtitle?: string
  profilePic?: string | null
  isActive: boolean
}

export interface ThreadSearchResult {
  searchId: string
  groupChatIcon: string
  effectiveParticipants: string[]
  groupName: string
  highlights: any
  messageId: string
  uploadedByUserId: string
  createdTime: string
  objectType: string
  objectID: string
  participantBusinesses: string[]
  participantNames: string[]
  participantFirstnames: string[]
  postedbyFirstname: string
  postedbyLastname: string
  searchObjectLevel: string
  searchText: string
  searchSubText: string
  threadId: string
  threadType: string
  fileName: string
  type: string // Keys to display videos images in gallery view
  key: string
  url: string
  mentionsMetadata: MentionMetadata
}

export interface MentionMetadata {
  [id: string]: MentionAffiliation
}

export interface MentionAffiliation {
  userId: string
  affiliationType: string
  businessId?: string
  businessLogo?: string
  businessName?: string
  firstName: string
  lastName: string
  participantStatus?: string
  profilePic: string
  roleType: string
  effectiveUserId: string
  email?: string
  phoneNumber?: string
  name?: string
  roleTemplateDisplayName: string
  roleTemplateId: string
  showHistory: boolean
  threadFilters: string[]
}

export interface SearchMediaType {
  id: string
  name: string
  iconName: string
}
