import React, { useEffect, useState } from 'react'
import { ImageUploader as GTImageUploader } from '@gotradie/gt-components'
import {
  MediaNotAvailableError,
  MediaTypeNotSupportedError,
} from '../common/Errors'
import { fetchMedia, Constants } from '../common'
import { segmentIdentifyUser, segmentLogEvent } from '../segment'
import { useFetchMyProfile } from '../hooks/user/useFetchUser'

type ImageUploaderProps = {
  selectedItemPath?: string
  selectedItemKey?: string
  mainText: string
}

const ImageUploader = (props: ImageUploaderProps) => {
  const { selectedItemPath, mainText, selectedItemKey } = props
  const { data: currentUser } = useFetchMyProfile()

  const [imageUri, setImageUri] = useState<string | null>(null)

  useEffect(() => {
    if (selectedItemPath) {
      const segmentUserDetails = {
        user_id: currentUser?.userId,
        phone_number: currentUser?.phoneNumber,
        first_name: currentUser?.firstName,
        last_name: currentUser?.lastName,
        email: currentUser?.email,
        profile_type: currentUser?.profileType,
      }
      segmentIdentifyUser(segmentUserDetails.user_id, segmentUserDetails)
      segmentLogEvent('add_new_account_profile_photo', {
        image_uri: selectedItemPath,
        selected_item_key: selectedItemKey,
        ...segmentUserDetails,
      })
      setImageUri(selectedItemPath)
    } else if (selectedItemKey && selectedItemKey.length > 0) {
      fetchMedia(selectedItemKey, Constants.IMAGE_SIZES.REPLICA)
        .then((uri: any) => {
          setImageUri(uri)
        })
        .catch((error: any) => {
          if (error instanceof MediaTypeNotSupportedError) {
            setImageUri(require('../images/broken-image.png'))
          } else if (error instanceof MediaNotAvailableError) {
            setImageUri(require('../images/broken-media.png'))
          } else {
            setImageUri(null)
          }
        })
    } else {
      setImageUri('')
    }
  }, [selectedItemKey, selectedItemPath])

  return (
    <GTImageUploader
      testIds={{
        onPressWithoutImageTestId: 'selectBusinessProfileUploaderContainer',
      }}
      selectedItemUrl={imageUri}
      mainText={mainText}
      detailText={'Hint: Square format works best'}
      buttonTitle={'Choose image'}
    />
  )
}

export default ImageUploader
