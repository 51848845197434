import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Icon,
  Button,
} from '@gotradie/gt-components'
import { GalleryItem, GalleyType, UploadItem } from '../../types/Files'
import VideoPlayer from '../media/VideoPlayer'
import Image from '../media/Image'
import { Constants } from '../../common'

const styles = StyleSheet.create({
  wrapperStyle: {
    width: '100%',
    height: '100%',
  },
  submitButtonWrapperStyle: {
    alignItems: 'flex-end',
    marginHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  previewContainer: {
    height: 'calc(100% - 15px)',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  previewContainerInner: {
    width: 375,
    height: '100%',
    borderRadius: ComponentOptions.SPACES.SMALL,
    backgroundColor: AppColors.common.whiteDrop,
    marginHorizontal: ComponentOptions.SPACES.MEDIUM,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

type ClipPreviewerProps = {
  media: UploadItem[]
  galleryMedia?: GalleryItem[]
  onSubmitClick: (stories: UploadItem[]) => void
  editMode: boolean
  onPressDelete: (item: UploadItem) => void
  selectedIndex: number
}

function ClipPreviewer(props: ClipPreviewerProps) {
  const { media, onSubmitClick, editMode, onPressDelete, selectedIndex } = props
  const [index, setIndex] = useState<number | undefined>(0)

  useEffect(() => {
    if (selectedIndex !== index && media[selectedIndex]) {
      setIndex(selectedIndex)
    } else {
      setIndex(
        media.length > 0 ? selectedIndex : undefined
      )
    }
  }, [media, selectedIndex])

  const prevClicked = () => {
    if (index !== undefined && index > 0) {
      setIndex(index - 1)
    }
  }

  const nextClicked = () => {
    if (index !== undefined && index < media.length - 1) {
      setIndex(index + 1)
    }
  }

  const renderImage = () => {
    if (index === undefined || !media[index]) {
      return null
    }
    const isPhoto = media[index].galleryType === GalleyType.photo
    const isUploaded =
      media[index].uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED

    if (isPhoto) {
      return (
        <Image
          source={isUploaded ? undefined : media[index].uploadingUrl}
          imageProps={{
            style: {
              width: '100%',
              height: '100%',
              resizeMode: 'contain',
              borderRadius: ComponentOptions.SPACES.SMALL,
            },
          }}
          s3Key={isUploaded ? media[index].objectId : undefined}
          size={Constants.IMAGE_SIZES.GALLERY_THUMB}
        />
      )
    }
    return null
  }

  const renderVideo = () => {
    if (index === undefined || !media[index]) {
      return null
    }
    const isVideo = media[index].galleryType === GalleyType.video
    const isUploaded =
      media[index].uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED
    if (isVideo) {
      return (
        <VideoPlayer
          source={isUploaded ? undefined : media[index].uploadingUrl}
          videoProps={{
            ...props,
            resizeMode: 'contain',
            width: '100%',
            height: '100%',
          }}
          hideControllers={true}
          isPlaying={true}
          wrapperStyles={{ padding: 0 }}
          s3Key={isUploaded ? media[index].objectId : undefined}
        />
      )
    }
    return null
  }

  const renderDeleteIcon = () => {
    if (index === undefined) {
      return null
    }
    return (
      <Icon
        name={'DetailedBin'}
        fill={AppColors.common.white}
        size={34}
        wrapperStyles={{
          padding: ComponentOptions.SPACES.SMALL,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        onPress={() => {
          setIndex(undefined)
          onPressDelete(media[index])
        }}
      />
    )
  }

  const renderSubmitButton = () => {
    return (
      <View style={styles.submitButtonWrapperStyle}>
        <Button
          title={`NEXT`}
          textProps={{ bidy: true }}
          paddingHorizontal={ComponentOptions.SPACES.SMALL}
          paddingVertical={ComponentOptions.SPACES.EXTRA_SMALL}
          width={80}
          height={38}
          borderRadius={100}
          buttonColor={AppColors.common.primary}
          buttonType={ComponentOptions.BUTTON_TYPES.SOLID}
          onPress={() => onSubmitClick(media)}
        />
      </View>
    )
  }

  return (
    <View
      style={{ height: editMode ? `calc(100% - 200px)` : `calc(100% - 120px)` }}
    >
      <View style={styles.previewContainer}>
        <Icon
          name={'ChevronLeft'}
          fill={AppColors.common.dark}
          size={30}
          wrapperStyles={{
            padding: ComponentOptions.SPACES.SMALL,
            backgroundColor: AppColors.common.white,
            borderRadius: ComponentOptions.SPACES.MEDIUM,
            marginTop: editMode ? 290 : 332,
            visibility: index === 0 ? 'hidden' : 'visible',
          }}
          onPress={() => prevClicked()}
        />
        <View style={styles.previewContainerInner}>
          {renderImage()}
          {renderVideo()}
          {editMode && renderDeleteIcon()}
        </View>
        <Icon
          name={'ChevronRight'}
          fill={AppColors.common.dark}
          size={30}
          wrapperStyles={{
            padding: ComponentOptions.SPACES.SMALL,
            backgroundColor: AppColors.common.white,
            borderRadius: ComponentOptions.SPACES.MEDIUM,
            marginTop: editMode ? 290 : 332,
            visibility: index === media.length - 1 ? 'hidden' : 'visible',
          }}
          onPress={() => nextClicked()}
        />
      </View>
      {editMode && renderSubmitButton()}
    </View>
  )
}

export default ClipPreviewer
