import { FlatList, View } from 'react-native'

import {
  Tile,
  ComponentOptions,
  Text,
  useTheme,
  AppStyles,
  AppColors,
} from '@gotradie/gt-components'

import StyleSheet from 'react-native-media-query'
import { Clip, GalleryItem } from '../../../types/Files'
import { TabPanel } from '../../../components/tabView'
import { TabProps } from '../../../types/Tab'
import ClipItem from '../../../components/ClipItem'
import { useFetchYourClips } from '../../../hooks/clip/useFetchClip'
import { useActiveOrgId } from '../../../context/UserContext'
import { Constants, Util } from '../../../common'
import { useState } from 'react'
import { DeleteClipParams } from '../../../types/ClipCentral'
import DeleteBottomDrawer from '../../../components/DeleteBottomDrawer'

const { styles } = StyleSheet.create({
  titleWrapperStyle: {
    paddingRight: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  clipCreateWrapperStyle: {
    paddingLeft: ComponentOptions.SPACES.MEDIUM,
    paddingRight: ComponentOptions.SPACES.SMALL,
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
  },
})
type YourClipsProps = {
  tab: TabProps
  isDeletingClip: boolean
  onPressClip: (clip: Clip) => void
  onPressCreateClip: () => void
  onLongPressClip: (clipData: DeleteClipParams) => void
}

type NewClip = { clipId: number; isNew: boolean }

type ClipCreateItemProps = {
  theme: any
  onPress: () => void
}

const ClipCreateItem = (props: ClipCreateItemProps) => {
  const { theme, onPress } = props

  const { width } = Util.getWindowDimensions()

  const padding = ComponentOptions.SPACES.MEDIUM

  const clipContainerWidth =
    (width < Constants.BREAK_POINT
      ? width - padding
      : Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL) * 0.5

  const clipContainerHeight = clipContainerWidth * 1.3

  const paddings = Util.getPaddings(2, padding, 0)

  const clipOverlayContainerStyle = {
    width: clipContainerWidth - (paddings.paddingLeft + paddings.paddingRight),
    height:
      clipContainerHeight - (paddings.paddingTop + paddings.paddingBottom),
  }

  return (
    <View style={styles.clipCreateWrapperStyle}>
      <Tile
        onPress={onPress}
        borderWidth={1}
        height={clipOverlayContainerStyle.height}
        width={clipOverlayContainerStyle.width}
        backgroundColor={theme.tertiary}
        borderColor={AppColors.common.primary}
        shadowStyle={AppStyles.shadowStyle.tile}
        renderBottomElement={
          <Text body_small color={theme.textColors.title}>
            Create clip
          </Text>
        }
        renderTopLeftElement={{
          fill: AppColors.common.primary,
          name: 'Add',
          size: 24,
          stroke: 1,
        }}
      />
    </View>
  )
}

const YourClips = (props: YourClipsProps) => {
  const {
    tab,
    onPressClip,
    onPressCreateClip,
    onLongPressClip,
    isDeletingClip,
  } = props

  const theme = useTheme()

  const [isVisibleDeleteClipDrawer, setIsVisibleDeleteClipDrawer] =
    useState<boolean>(false)
  const [deletingClip, setDeletingClip] = useState<DeleteClipParams>()

  const orgId = useActiveOrgId()
  const { data: items, isLoading: isLoading } = useFetchYourClips(orgId)

  const data = (items || []) as Clip[]

  const clips: Clip[] | NewClip[] = [
    { clipId: -1, isNew: true } as NewClip,
    ...data,
  ]
  return (
    <TabPanel key={`your_clips`} displayKey={tab.tabKey}>
      <FlatList
        data={clips as Clip[]}
        extraData={clips}
        numColumns={2}
        renderItem={({ item, index }) =>
          item?.isNew && !isLoading ? (
            <ClipCreateItem theme={theme} onPress={onPressCreateClip} />
          ) : (
            <ClipItem
              item={item}
              isLoading={
                isLoading ||
                (deletingClip?.clipId === item.clipId && isDeletingClip)
              }
              index={index}
              columns={2}
              onPressItem={(item: GalleryItem) => onPressClip(item as Clip)}
              onLongPressItem={(clipId) => {
                setDeletingClip({
                  clipId: clipId,
                  threadId: item.threadId,
                })
                setIsVisibleDeleteClipDrawer(true)
              }}
            />
          )
        }
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => `sectionItem${item.recordKey}${index}`}
      />
      <DeleteBottomDrawer
        isVisible={isVisibleDeleteClipDrawer}
        title={'Remove entire clip?'}
        subTitle={
          'If this clips is shared, collaborators can no longer view or edit this clips. It will be permanently deleted.'
        }
        onModalHide={() => setIsVisibleDeleteClipDrawer(false)}
        onPress={() => {
          onLongPressClip(deletingClip as DeleteClipParams)
          setIsVisibleDeleteClipDrawer(false)
        }}
      />
    </TabPanel>
  )
}

export default YourClips
