import React from 'react'
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { Constants, Util } from '../../common'
import { SelectedSlideGalleryItem, SlideType, UploadStatus } from '../../types/Files'
import Image from '../media/Image'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  Icon,
  CircularProgress,
  ComponentOptions,
} from '@gotradie/gt-components'
import LinearGradient from 'react-native-linear-gradient'
import {
  SlideGalleryItemProps,
  SlideGalleryItem,
} from '../../types/ClipCentral'

const { styles } = StyleSheet.create({
  videoContainerStyle: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    backgroundColor: AppColors.common.tertiaryDrop,
    width: 40,
    height: 40,
    borderRadius: 27,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 1000,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  uploadContainerStyle: {
    zIndex: 10000,
    position: 'absolute',
  },
  uploadCenterElementContainer: {
    zIndex: 20000,
    position: 'absolute',
    top: '35%',
    left: '40%',
  },
  uploadRightButtonContainer: {
    position: 'absolute',
    right: ComponentOptions.SPACES.SMALL,
    top: ComponentOptions.SPACES.SMALL,
    zIndex: 20000,
  },
  headerRightIcon: {
    position: 'absolute',
    top: ComponentOptions.SPACES.SMALL,
    right: ComponentOptions.SPACES.SMALL,
    alignItems: 'center',
    justifyContent: 'center',
    width: ComponentOptions.SPACES.MEDIUM,
    height: ComponentOptions.SPACES.MEDIUM,
    borderRadius: ComponentOptions.SPACES.MEDIUM,
    zIndex: 1000,
    borderWidth: 1.25,
    borderColor: AppColors.common.white,
  },
})

type ImageItemProps = {
  item: SlideGalleryItem
  containerStyle?: ViewStyle
}
const ImageItem = (props: ImageItemProps) => {
  const { item, containerStyle } = props
  const imageProps = item.uploadingUrl
    ? { source: { uri: item.uploadingUrl } }
    : {}

  return (
    <Image
      s3Key={item.slideKey}
      size={Constants.IMAGE_SIZES.GALLERY_THUMB}
      imageProps={{
        style: [styles.imageStyle, containerStyle || {}],
        ...imageProps,
      }}
      imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
      mediaNotAvailablePlaceholderUri={require('../../images/not-available-gallery.png')}
      mediaNotSupportedPlaceholderUri={require('../../images/not-supported-gallery.png')}
    />
  )
}

type VideoItemProps = {
  item: SlideGalleryItem
  containerStyle?: ViewStyle
}
const VideoItem = (props: VideoItemProps) => {
  const { item, containerStyle } = props
  return (
    <View style={[styles.videoContainerStyle, containerStyle || {}]}>
      <View style={styles.playButton}>
        <Icon name={'DetailedPlay'} size={18} />
      </View>
      <Image
        s3Key={item.thumbnailKey}
        size={Constants.IMAGE_SIZES.GALLERY_THUMB}
        imageProps={{ style: styles.imageStyle }}
        imageFetchType={Constants.IMAGE_FETCH_TYPE.VIDEO_THUMBNAIL}
        mediaNotAvailablePlaceholderUri={require('../../images/not-available-gallery.png')}
        mediaNotSupportedPlaceholderUri={require('../../images/not-supported-gallery.png')}
      />
    </View>
  )
}

type ClipMediaWrapperProps = {
  item: SlideGalleryItem
  children: React.ReactNode
  onPressItem: (item: SlideGalleryItem) => void
  onRemoveUploadingItem?: (item: SlideGalleryItem) => void
  onRetryUpload?: (item: SlideGalleryItem) => void
  style: ViewStyle
}
const ClipMediaWrapper = (props: ClipMediaWrapperProps) => {
  const {
    item,
    children,
    style,
    onPressItem,
    onRetryUpload,
  } = props

  if (item.uploadStatus === UploadStatus.uploaded) {
    return (
      <TouchableOpacity style={style} onPress={() => onPressItem(item)}>
        {children}
      </TouchableOpacity>
    )
  }
  return (
    <View style={style}>
      <LinearGradient
        style={{ ...style, ...styles.uploadContainerStyle }}
        colors={[AppColors.common.transparent, AppColors.common.dark]}
        locations={[0.1, 0.9]}
      ></LinearGradient>

      {item.uploadStatus === UploadStatus.uploadFailed && (
        <View style={styles.uploadRightButtonContainer}>
          <Icon
            name={'Replay'}
            fill={AppColors.common.white}
            size={15}
            onPress={() => onRetryUpload?.(item)}
          />
        </View>
      )}

      <View style={styles.uploadCenterElementContainer}>
        {item.uploadStatus === UploadStatus.uploading && (
          <CircularProgress
            percentage={item.uploadingStatusValue}
            radius={15}
            strokeBackgroungColorOpacity={0.4}
            strokeWidth={3}
            strokeBackgroungColor={AppColors.common.white}
            strokeColor={AppColors.common.secondery}
          />
        )}
        {item.uploadStatus === UploadStatus.updatingAPI && (
          <ActivityIndicator color={AppColors.common.primary} size={'small'} />
        )}
        {item.uploadStatus === UploadStatus.uploadFailed && (
          <Icon name={'Alert'} fill={AppColors.common.white} size={20} />
        )}
      </View>

      {props.children}
    </View>
  )
}

type RightIconProps = {
  selectedItems: SelectedSlideGalleryItem[]
  itemId: string
  enableGallerySelection: boolean
}
const RightIcon = ({
  selectedItems,
  itemId,
  enableGallerySelection,
}: RightIconProps) => {
  const isSelected = selectedItems?.some((item) => item.slideId === itemId)
  return enableGallerySelection && (
    <View
      style={[
        styles.headerRightIcon,
        {
          backgroundColor: isSelected
            ? AppColors.common.primary
            : AppColors.common.concrete,
        },
      ]}
    >
      {isSelected && (
        <Icon name={'Tick'} fill={AppColors.common.white} size={6} stroke={5} />
      )}
    </View>
  )
}
interface ClipMediaItemProps extends SlideGalleryItemProps {
  item: SlideGalleryItem
  index: number
  columns: number
  enableGallerySelection?: boolean
  onRetryUpload?: (item: SlideGalleryItem) => void
  onRemoveUploadingItem?: (item: SlideGalleryItem) => void
  containerStyle?: ViewStyle
  innerItemStyle?: ViewStyle
  padding: number
  selectedItems?: any
}

const ClipGalleryMediaItem = (props: ClipMediaItemProps) => {
  const {
    item,
    columns,
    index,
    enableGallerySelection,
    onPressItem,
    onRemoveUploadingItem,
    onRetryUpload,
    containerStyle: passedContainerStyle,
    innerItemStyle,
    padding,
    selectedItems,
  } = props
  const { width } = Util.getWindowDimensions()
  const mediaContainerWidth =
    (width < Constants.BREAK_POINT
      ? width
      : Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL) / 3

  const paddings = Util.getPaddings(columns || 1, padding, index || 0)
  const containerStyle = {
    width: mediaContainerWidth,
    height: mediaContainerWidth,
    ...paddings,
    ...(passedContainerStyle ? passedContainerStyle : {}),
  }

  const rightIconItem = {
    selectedItems: selectedItems ?? [],
    props: item,
    itemId: item.slideId,
    enableGallerySelection,
  } as RightIconProps

  return (
    <ClipMediaWrapper
      item={item}
      onPressItem={onPressItem}
      onRemoveUploadingItem={onRemoveUploadingItem}
      onRetryUpload={onRetryUpload}
      style={containerStyle}
    >
      {item.slideType === SlideType.video && (
        <VideoItem item={item} containerStyle={innerItemStyle} />
      )}
      {(item.slideType === SlideType.photo ||
        item.slideType === SlideType.empty) && (
        <ImageItem item={item} containerStyle={innerItemStyle} />
      )}
      {RightIcon && RightIcon(rightIconItem)}
    </ClipMediaWrapper>
  )
}

export default ClipGalleryMediaItem
