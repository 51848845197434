import { useMutation, useQueryClient } from 'react-query'
import { getAxios, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { ServerError } from '../../types/Common'
import { AxiosError } from 'axios'
import { DeleteClipParams, DeleteSlideParams, DeleteSlidesParams } from '../../types/ClipCentral'
import { GalleryTab } from '../../types/Files'

const api = getAxios()

const saveClips = async (clips: any) => {
  return await api.post('/clips', clips)
}

const deleteClip = async (data: DeleteClipParams) => {
  return await api.delete(`/clips/${data.clipId}`)
}

const deleteSlide = async (data: DeleteSlideParams) => {
  return await api.delete(`/clips/${data.clipId}/slides/${data.slideId}`)
}

const deleteSlides = async (params: DeleteSlidesParams) => {
  const data = {
    slides: params.slides
  }
  return await api.delete(`/clips/${params.clipId}/slides`, { data })
}

type addToHighlightsParams = {
  businessId: string
  highlights: string[]
  activeUserId: string
}
const addToHighlights = async (data: addToHighlightsParams) => {
  return await api.post(`/business/${data.businessId}/clips/highlights`, {
    highlights: data.highlights,
  })
}

export const useSaveClips = (
  onSuccess: (data: any, variable: any) => void,
  onErrorCallback: (error: ServerError) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(saveClips, {
    onSuccess,
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
    onSettled: (clipsObj, error, variables) => {
      if (variables?.['posted-by']) {
        const userId = variables?.['posted-by']
        queryClient.invalidateQueries([
          QueryKeys.TAGGED_CLIPS,
          userId,
          activeOrgId,
        ])
      }
      queryClient.invalidateQueries([
        QueryKeys.YOUR_CLIPS,
        activeOrgId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.CLIP_HIGHLIGHTS,
        activeOrgId,
        activeOrgId,
      ])
    },
  })
}

export const useSaveClipHighlights = (
  onSuccess: () => void,
  onErrorCallback: (error: ServerError) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(addToHighlights, {
    onSuccess,
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
    onSettled: (data, error, variables) => {
      if (variables?.activeUserId) {
        const userId = variables?.activeUserId
        queryClient.invalidateQueries([
          QueryKeys.TAGGED_CLIPS,
          userId,
          activeOrgId,
        ])
      }
      queryClient.invalidateQueries([
        QueryKeys.YOUR_CLIPS,
        activeOrgId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.CLIP_HIGHLIGHTS,
        activeOrgId,
        activeOrgId,
      ])
    },
  })
}

export const updateClip = async (clips: any) => {
  return await api.put(`/clips/${clips['clip-id']}`, clips)
}

export const useUpdateClip = (
  onSuccess: (data: any, variables: any) => void,
  onErrorCallback: (error: ServerError) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(updateClip, {
    onSuccess,
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
    onSettled: (clipsObj, error, variables) => {
      if (variables?.['clip-id']) {
        queryClient.invalidateQueries([
          QueryKeys.CLIP,
          variables?.['clip-id'],
          activeOrgId,
        ])
      }
      if (variables?.['posted-by']) {
        const userId = variables?.['posted-by']
        queryClient.invalidateQueries([
          QueryKeys.TAGGED_CLIPS,
          userId,
          activeOrgId,
        ])
      }
      queryClient.invalidateQueries([
        QueryKeys.YOUR_CLIPS,
        activeOrgId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.CLIP_HIGHLIGHTS,
        activeOrgId,
        activeOrgId,
      ])
    },
  })
}

export const useDeleteClip = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteClip, {
    onSuccess,
    onError,
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([
        QueryKeys.CLIP,
        variables.clipId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.THREAD,
        variables.threadId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.THREAD_MEDIA,
        variables.threadId,
        GalleryTab.clips,
        activeOrgId,
      ])
      queryClient.invalidateQueries([QueryKeys.YOUR_CLIPS, activeOrgId])
      queryClient.invalidateQueries([QueryKeys.CLIP_HIGHLIGHTS, activeOrgId])
    },
  })
}

export const useDeleteSlide = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteSlide, {
    onSuccess,
    onError,
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries([
        QueryKeys.CLIP,
        variables.clipId,
        activeOrgId,
      ])
      if (variables.threadId) {
        queryClient.invalidateQueries([
          QueryKeys.THREAD,
          variables.threadId,
          activeOrgId,
        ])
      }
    },
  })
}

export const useDeleteSlides = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteSlides, {
    onSuccess,
    onError,
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries([
        QueryKeys.CLIP,
        variables.clipId,
        activeOrgId,
      ])
      if (variables.threadId) {
        queryClient.invalidateQueries([
          QueryKeys.THREAD,
          variables.threadId,
          activeOrgId,
        ])
      }
    },
  })
}
