import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'
import NavigationStack from './navigation/NavigationStack'
import { Hub } from 'aws-amplify'
import { ThemeProvider, themes, FlashMessage } from '@gotradie/gt-components'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  useActiveOrgId,
  useActiveUserId,
  UserContextProvider,
  useUserContext,
} from './context/UserContext'
import ContentWrapper from './layout/ContentWrapper'
import { AuthUtil, Constants, MessageUtils, QueryKeys } from './common'
import ClientWebSocket from './common/ClientWebSocket'
import NotificationUtil from './common/NotificationUtil'
import { activate, fetchConfig } from 'firebase/remote-config'
import { remoteConfig } from './remoteConfig'
import { useFetchUnreadThreadsCount } from './hooks/message/useFetchThread'

function Content() {
  const activeUserId = useActiveUserId()
  const activeOrgId = useActiveOrgId()
  const queryClient = useQueryClient()
  const {
    activeTheme,
    isAuthenticated,
    setAuthenticated,
    setFirebaseAuthenticate,
  } = useUserContext()
  const { data: unreadThreadsCount, refetch } = useFetchUnreadThreadsCount()

  const [isLoading, setLoading] = useState(true)

  window.addEventListener('focus', onWindowFocused)
  document.addEventListener('visibilitychange', setTitleUnreadCount)

  useEffect(() => {
    fetchConfig(remoteConfig)
    activate(remoteConfig)
    AuthUtil.getCurrentSession()
      .then((session) => {
        setAuthenticated(session && session.isValid())
      })
      .catch(() => {
        setAuthenticated(false)
        setLoading(false)
      })

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn': {
          AuthUtil.signInToFirebase(
            (state) => setFirebaseAuthenticate(state),
            setLoading
          )
          break
        }

        case 'signOut': {
          AuthUtil.signOutFromFirebase(
            (state) => setFirebaseAuthenticate(state),
            setLoading
          )
          break
        }
      }
    })

    return () => {
      window.removeEventListener('focus', onWindowFocused)
      !!ws && ws.closeWebSocket()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      AuthUtil.signInToFirebase(
        (state) => setFirebaseAuthenticate(state),
        setLoading
      )
    }
  }, [isAuthenticated])

  let ws: ClientWebSocket
  useEffect(() => {
    if (!activeUserId || !activeOrgId) {
      return
    }
    invalidateIndicators()
    if (isAuthenticated) {
      !!!ws && initWebSocket()
      AuthUtil.setSentryUserInfo(activeUserId, activeOrgId)
    } else {
      !!ws && ws.closeWebSocket()
    }
  }, [isAuthenticated, activeUserId, activeOrgId])

  function onWindowFocused() {
    setTitleUnreadCount()
    invalidateIndicators()
    !!ws && ws.reconnectIfDisconnected() //Web Socket disconnects if the window is out of focus for more than 5 min
  }

  function setTitleUnreadCount() {
    const unreadCount = unreadThreadsCount
      ? unreadThreadsCount?.worksites +
        unreadThreadsCount?.teams +
        unreadThreadsCount?.clients
      : 0
    if (unreadCount === 0 || document.visibilityState === 'visible') {
      document.title = `${Constants.TITLE}`
    } else {
      document.title = `(${unreadCount < 100 ? unreadCount : '99+'}) ${
        Constants.TITLE
      }`
    }
  }

  function initWebSocket() {
    ws = ClientWebSocket.getWebSocket(activeUserId, activeOrgId)
    ws.initiateHeartBeat()
    ws.subscribe(onNotification)
  }

  function invalidateIndicators() {
    MessageUtils.invalidateNotificationIndicatorQueries(
      queryClient,
      activeOrgId
    )
  }

  function onNotification(received: any) {
    if (received?.['message-id']) {
      queryClient.invalidateQueries([
        QueryKeys.UNREAD_THREADS_COUNT,
        '',
        activeOrgId,
      ])
      refetch()
    }
    if (received.action === Constants.WEB_SOCKET_MESSAGE_ACTIONS.NOTIFICATION) {
      //handle forground notifications
      console.log('notification payload received', received)

      //TODO show notification
      queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST, activeOrgId])
      //invalidate home page widgets
      // queryClient.invalidateQueries([QueryKeys.WIDGETS, activeOrgId])

      // refetch notifications
      queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST, activeOrgId])

      //TODO check the dto mapping
      const scenario = NotificationUtil.findNotificationScenario(received)
      if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_ACCEPTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.MY_PROFILE)
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      } else if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_REJECTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      }
    }
  }

  return (
    <ThemeProvider theme={themes[activeTheme]}>
      <ContentWrapper isLoading={isLoading} fillScreen={true}>
        <NavigationStack />
        <FlashMessage
          floating={true}
          duration={30000}
          testID={'flashMessage'}
        />
      </ContentWrapper>
    </ThemeProvider>
  )
}

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Content />
        <ReactQueryDevtools initialIsOpen={false} />
      </UserContextProvider>
    </QueryClientProvider>
  )
}
