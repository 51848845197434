import { initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'
import { Constants } from '../common'

const app = initializeApp(Constants.FIREBASE_CONFIG)
export const remoteConfig = getRemoteConfig(app)

remoteConfig.defaultConfig = {
  ALLOWED_COUNTRY_LIST: `${process.env.DEFAULT_COUNTRY_LIST}`,
}

//TODO Fetch data hourly. Need to change when scalling
remoteConfig.settings.minimumFetchIntervalMillis = 3600000

export function activateRemoteConfig() {
  fetchAndActivate(remoteConfig)
}

export const allowedCountryList = JSON.parse(
  getValue(remoteConfig, 'ALLOWED_COUNTRY_LIST').asString()
)?.countries
