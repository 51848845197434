import React, { useEffect, useState } from 'react'
import MainContent from '../../layout/MainContentV2'
import DefaultView from '../../components/home/mainContent/DefaultView'
import { HomeSection } from '../../types/Home'
import ClipMediaSelection from '../../components/clipCreation/ClipMediaSelection'
import { ClipMode } from '../../types/Message'
import { ClipOriginType, UploadItem } from '../../types/Files'

import ClipSubmission from '../../components/clipCreation/ClipSubmission'

type ClipCreationScreenProps = {
  clipEditMode: ClipMode
  setDroppedFiles: (files: UploadItem[]) => void
  setShowAddClipView: (isVisible: boolean) => void
  onPressNextAddClip: (stories: UploadItem[]) => void
  isEmptyClipCreation?: boolean
}

const ClipCreationScreen = (props: ClipCreationScreenProps) => {
  const {
    clipEditMode,
    setShowAddClipView,
    setDroppedFiles,
    onPressNextAddClip,
    isEmptyClipCreation,
  } = props
  return (
    <ClipMediaSelection
      onClose={() => {
        setDroppedFiles([])
        setShowAddClipView(false)
      }}
      onPressAddClipNext={(stories) => {
        onPressNextAddClip(stories)
      }}
      editMode={clipEditMode === ClipMode.edit}
      isEmptyClipCreation={isEmptyClipCreation}
    />
  )
}

enum RightPanelScreen {
  ADD_CLIP_DETAILS = 'ADD_CLIP_DETAILS',
  ADD_EMPTY_CLIP_DETAILS = 'ADD_EMPTY_CLIP_DETAILS',
}

const RightPanel = (props: {
  setRightPanelStack: (screens: RightPanelScreen[]) => void
  rightPanelStack: RightPanelScreen[]
  clipMedia: UploadItem[]
  onCloseClips: () => void
}) => {
  const { setRightPanelStack, rightPanelStack, clipMedia, onCloseClips } = props

  if (
    rightPanelStack[0] === RightPanelScreen.ADD_CLIP_DETAILS ||
    rightPanelStack[0] === RightPanelScreen.ADD_EMPTY_CLIP_DETAILS
  ) {
    return (
      <ClipSubmission
        selectedMedia={clipMedia}
        onClose={() => {
          setRightPanelStack([])
          onCloseClips()
        }}
        clipOriginType={ClipOriginType.PROFILE}
        isEmptyClipCreation={
          rightPanelStack[0] === RightPanelScreen.ADD_EMPTY_CLIP_DETAILS
        }
      />
    )
  }
  return null
}
const MemorizedRightPanel = React.memo(RightPanel)

type HomeMainContentProps = {
  type: HomeSection
  onCloseMainContent: () => void
}

const HomeMainContent = (props: HomeMainContentProps) => {
  const { type, onCloseMainContent } = props
  const [rightPanelStack, setRightPanelStack] = useState<RightPanelScreen[]>([])

  const [droppedFiles, setDroppedFiles] = useState<UploadItem[]>([])
  const [clipMedia, setClipMedia] = useState<UploadItem[]>([])
  const [clipEditMode, setClipEditMode] = useState<ClipMode>(ClipMode.edit)

  useEffect(() => {
    if (type === HomeSection.CreateEmptyClip) {
      setRightPanelStack([RightPanelScreen.ADD_EMPTY_CLIP_DETAILS])
    }
  }, [type])

  return (
    <MainContent
      rightPanel={
        rightPanelStack.length > 0 && (
          <MemorizedRightPanel
            setRightPanelStack={setRightPanelStack}
            rightPanelStack={rightPanelStack}
            clipMedia={[...droppedFiles, ...clipMedia]}
            onCloseClips={() => {
              setClipEditMode(ClipMode.close)
              onCloseMainContent()
            }}
          />
        )
      }
    >
      {type === HomeSection.default && <DefaultView />}
      {(type === HomeSection.CreateNewClip ||
        type === HomeSection.CreateEmptyClip) && (
        <ClipCreationScreen
          setDroppedFiles={setDroppedFiles}
          setShowAddClipView={() => {
            onCloseMainContent()
            setRightPanelStack([])
          }}
          isEmptyClipCreation={type === HomeSection.CreateEmptyClip}
          onPressNextAddClip={(stories: UploadItem[]) => {
            setClipMedia(stories)
            setClipEditMode(ClipMode.preview)
            setRightPanelStack([
              RightPanelScreen.ADD_CLIP_DETAILS,
              ...rightPanelStack,
            ])
          }}
          clipEditMode={clipEditMode}
        />
      )}
    </MainContent>
  )
}

export default HomeMainContent
