import { useState, useRef, useEffect } from 'react'
import { AxiosError } from 'axios'
import { View, SectionList, ActivityIndicator } from 'react-native'
import { Clip, Document, GalleryItem, GalleryTab, SelectedGalleryItem } from '../../types/Files'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  Input,
  Icon,
  Text,
} from '@gotradie/gt-components'
import { useFetchAllThreads } from '../../hooks/message/useFetchThread'
import { useFetchMyProfile } from '../../hooks/user/useFetchUser'
import { useSendMessageToThread } from '../../hooks/message/useMutateMessage'
import { useQueryClient } from 'react-query'
import { MessageUtils, Util, QueryKeys, Constants } from '../../common'
import { ShareStatus, ThreadShareItem } from '../../types/Thread'
import MemorizedThreadListItem from './ThreadListItem'
import { useActiveOrgId } from '../../context/UserContext'
import PanelHeader from '../PanelHeader'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
    backgroundColor: AppColors.common.white,
  },
  searchInputWrapperStyle: {
    backgroundColor: AppColors.common.searchInputBackground,
    borderColor: AppColors.common.searchBorder,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    marginBottom: ComponentOptions.SPACES.MEDIUM,
  },
  sectionHeaderStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  headerWrapperStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  emptyContainerStyle: {
    width: '100%',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type SearchInputProps = {
  isLoading: boolean
  onChangeQuery: (txt: string) => void
  query: string
  textInputRef: any
}
const SearchInput = (props: SearchInputProps) => {
  const { isLoading, onChangeQuery, query, textInputRef } = props
  return (
    <Input
      InputWrapperStyle={styles.searchInputWrapperStyle}
      leftIcon={
        <Icon
          testIds={{
            iconOnPressTestId: 'clipSearchScreenBackButton',
          }}
          fill={AppColors.common.subtitleText}
          name="Search"
          size={20}
        />
      }
      refNode={(input: any) => {
        textInputRef.current = input
      }}
      rightIcon={
        query.length === 0 ? (
          {}
        ) : (
          <Icon
            testIds={{
              iconOnPressTestId: 'clipSearchPreviewClearSearch',
            }}
            fill={AppColors.common.subtitleText}
            name="Error"
            size={18}
            onPress={() => {
              onChangeQuery('')
              if (textInputRef.current) {
                textInputRef?.current?.clear()
                textInputRef?.current?.focus()
              }
            }}
          />
        )
      }
      value={query}
      loading={isLoading}
      InputStyles={{ color: AppColors.common.tertiary }}
      placeholderTextColor={AppColors.common.subtitleText}
      onChangeText={(txt: string) => onChangeQuery(txt)}
    />
  )
}

const ListEmptySection = () => {
  return (
    <View style={styles.emptyContainerStyle}>
      <ActivityIndicator color={AppColors.common.primary} />
    </View>
  )
}

enum CHAT_THREADS {
  RECENT = 'RECENT',
  OTHER = 'OTHER',
}

type Chats = {
  title: string
  kind: string
  type: CHAT_THREADS
  showWhenEmpty: boolean
  displayCountWithTitle: boolean
  data: ThreadShareItem[] | { id: string; isLoading: boolean }[]
}

type ThreadListProps = {
  tab?: string
  item?: GalleryItem
  items?: SelectedGalleryItem[]
  onCancel: () => void
}

const ThreadList = (props: ThreadListProps) => {
  const { onCancel, tab, item, items } = props
  const queryClient = useQueryClient()
  const {
    data: chatThreads,
    isLoading: isLoadingThreads,
    hasNextPage,
    fetchNextPage,
  } = useFetchAllThreads()

  const { mutate: sendMessageToThreadApi } = useSendMessageToThread(
    (data, variables) => onSuccessShare(variables),
    onErrorShare
  )

  const { data: currentUserProfile } = useFetchMyProfile()
  const orgId = useActiveOrgId()
  const textInputRef = useRef()
  const [searchText, setSearchText] = useState('')
  const [allChatThreads, setArAllChatThreads] = useState<Chats[]>([])
  const [clickedThreads, setClickedThreads] = useState<ThreadShareItem[]>([])

  useEffect(() => {
    const modifiedResult: ThreadShareItem[] = (chatThreads?.pages || []).map(
      (v) => {
        const item = clickedThreads.find((item) => item.threadId === v.threadId)
        return {
          ...v,
          searchText: MessageUtils.getThreadTitle(
            v,
            currentUserProfile?.userId || ''
          ),
          shareStatus: item ? item.shareStatus : ShareStatus.notSend,
        }
      }
    )
    setArAllChatThreads(convertDataToResult(modifiedResult, searchText))
  }, [chatThreads, searchText, clickedThreads])

  function invalidateThreadListQuery() {
    currentUserProfile?.chatFilters.forEach((v) => {
      queryClient.invalidateQueries([QueryKeys.THREADS, v, orgId])
    })
  }

  const onShareMediaToMessage = (thread: ThreadShareItem) => {
    const myEffectiveUserId = MessageUtils.getEffectiveUserId(
      thread,
      currentUserProfile?.userId || ''
    )

    const messageObj: any = {
      'posted-by': myEffectiveUserId,
      'user-id': currentUserProfile?.userId,
      'message-id': `${MessageUtils.generateMessageUUID()}`,
      'forwarded-thread-id': thread.threadId,
      'forwarded-message': true,
      'no-display-forward-indicator': true,
      message: '',
    }

    if (items && items?.length > 0) {
      const dataArray: any[] = []

      if (tab === GalleryTab.media) {
        items.forEach((item) => {
          const obj = {
            type: item.type,
            key: item.id,
          }
          dataArray.push(obj)
        })
      } else if (tab === GalleryTab.docs) {
        items.forEach((item) => {
          const obj = {
            'file-name': item.item?.fileName,
            type: Constants.MESSAGE_MEDIA_TYPE.DOCUMENT,
            key: item.id,
          }
          dataArray.push(obj)
        })
      } else if (tab === GalleryTab.clips) {
        items.forEach((item) => {
          const obj = {
            'clip-id': item.id,
            type: Constants.MESSAGE_MEDIA_TYPE.CLIP,
          }
          dataArray.push(obj)
        })
      }
      messageObj['metadata'] = {
        media: dataArray,
      }
    } else {
      if (item?.tab === GalleryTab.media) {
        messageObj['metadata'] = {
          media: [
            {
              key: item.objectId,
              type: item.galleryType,
            },
          ],
        }
      } else if (item?.tab === GalleryTab.docs) {
        const file = item as Document
        messageObj['metadata'] = {
          media: [
            {
              'file-name': file.fileName,
              type: Constants.MESSAGE_MEDIA_TYPE.DOCUMENT,
              key: file.objectId,
            },
          ],
        }
      } else if (item?.tab === GalleryTab.clips) {
        const clip = item as Clip
        messageObj['metadata'] = {
          media: [
            {
              'clip-id': clip.clipId,
              type: Constants.MESSAGE_MEDIA_TYPE.CLIP,
            },
          ],
        }
      }
    }

    thread.shareStatus = ShareStatus.sending
    updateClickedThreads(thread, ShareStatus.sending)
    sendMessageToThreadApi({ threadId: thread.threadId, message: messageObj })
  }

  function updateClickedThreads(
    item: ThreadShareItem | string,
    status: ShareStatus
  ) {
    const threadId: string = typeof item === 'string' ? item : item.threadId
    const foundItem = clickedThreads.find((v) => threadId === v.threadId)
    if (foundItem) {
      const arUpdated = clickedThreads.map((v) => {
        if (threadId === v.threadId) {
          return { ...v, shareStatus: status }
        }
        return v
      })
      setClickedThreads(arUpdated)
    } else if (typeof item === 'object') {
      clickedThreads.push(item)
      setClickedThreads(clickedThreads)
    }
  }

  function onSuccessShare(variables: any) {
    updateClickedThreads(variables?.threadId, ShareStatus.sent)
    invalidateThreadListQuery()
  }

  function onErrorShare(er: AxiosError, variables: any) {
    updateClickedThreads(variables?.threadId, ShareStatus.notSend)
    Util.showErrorMessage(er?.response?.data.message || "Can't share item")
  }

  function filterAccountsWithSearchText(ar: ThreadShareItem[], txt: string) {
    if (txt.length > 0) {
      return ar.filter((obj: ThreadShareItem) =>
        obj.searchText.toUpperCase().includes(txt.toUpperCase())
      )
    } else {
      return ar
    }
  }

  const convertDataToResult = (ar: ThreadShareItem[], searchText: string) => {
    const filteredThreads = filterAccountsWithSearchText(ar, searchText)

    const chunkedArray = Util.chunkArrayToGivenSizes(filteredThreads, [
      5,
      filteredThreads.length,
    ])

    const emptyArray = isLoadingThreads
      ? Array.from(Array(5)).map((item) => ({
          threadId: `emptyItem${item}`,
          isLoading: true,
        }))
      : []
    return [
      {
        title: 'Recent chats',
        kind: 'list',
        type: CHAT_THREADS.RECENT,
        showWhenEmpty: false,
        displayCountWithTitle: true,
        data: chunkedArray.length > 0 ? chunkedArray[0] : emptyArray,
      },
      {
        title: 'Other',
        kind: 'list',
        type: CHAT_THREADS.OTHER,
        showWhenEmpty: false,
        displayCountWithTitle: true,
        data: chunkedArray.length > 1 ? chunkedArray[1] : emptyArray,
      },
    ]
  }

  return (
    <View style={styles.containerStyle}>
      <SectionList
        sections={allChatThreads as any}
        extraData={clickedThreads || allChatThreads}
        renderItem={({ item }) => {
          return (
            <MemorizedThreadListItem
              key={`item-` + item.threadId}
              onPress={(thread: ThreadShareItem) =>
                onShareMediaToMessage(thread)
              }
              threadShareItem={item?.isLoading ? undefined : item}
              currentUser={currentUserProfile}
              isLoading={isLoadingThreads} //TODO:change to send status
            />
          )
        }}
        showsHorizontalScrollIndicator={false}
        ListEmptyComponent={<ListEmptySection />}
        ListHeaderComponent={
          <View style={styles.headerWrapperStyle}>
            <PanelHeader
              onCancel={onCancel}
              cancelText={'Cancel'}
              doneText={'Done'}
              onPressDone={onCancel}
            />
            <SearchInput
              isLoading={isLoadingThreads}
              textInputRef={textInputRef}
              onChangeQuery={(text) => setSearchText(text)}
              query={searchText}
            />
          </View>
        }
        renderSectionHeader={({ section: { title, data } }) => {
          if (data.length > 0) {
            return (
              <View style={styles.sectionHeaderStyle}>
                <Text body_small color={AppColors.common.subtitleText}>
                  {title}
                </Text>
              </View>
            )
          }
          return null
        }}
        onEndReachedThreshold={0.01}
        onEndReached={() => {
          if (hasNextPage) {
            fetchNextPage()
          }
        }}
      />
    </View>
  )
}

export default ThreadList
