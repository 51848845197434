import { AppRegistry } from 'react-native'
import { App } from './App'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '1'),
  environment: process.env.NODE_ENV,
  beforeSend: (event) => {
    const host = window.location.hostname
    if (host === 'localhost' || host === '0.0.0.0') {
      return null
    }
    return event
  },
})

AppRegistry.registerComponent('GoTradieApp', () => App)
AppRegistry.runApplication('GoTradieApp', {
  initialProps: {},
  rootTag: document.getElementById('root'),
})
