import { FlatList } from 'react-native'

import { SlideGalleryItem } from '../../../types/ClipCentral'
import ClipGalleryMediaItem from '../ClipGalleryMediaItem'
import { SelectedGalleryItem } from '../../../types/Files'

type ClipSlideMediaSectionProps = {
  items: SlideGalleryItem[]
  enableGallerySelection?: boolean
  selectedItems?: SelectedGalleryItem
  onRetryUpload: (item: SlideGalleryItem) => void
  onRemoveUploadingItem: (item: SlideGalleryItem) => void
  setClipSlidesCount: (itemCount: number) => void
  onMediaPress?: (slide: SlideGalleryItem) => void
}
const ClipSlideMediaSection = (props: ClipSlideMediaSectionProps) => {
  const {
    items,
    enableGallerySelection,
    selectedItems,
    onRetryUpload,
    onRemoveUploadingItem,
    setClipSlidesCount,
    onMediaPress,
  } = props

  setClipSlidesCount(items.length)

  return (
    <FlatList
      data={items}
      extraData={items}
      numColumns={3}
      renderItem={({ item, index }) => {
        return (
          <ClipGalleryMediaItem
            index={index}
            columns={3}
            item={item}
            onPressItem={
              onMediaPress ? (slide) => onMediaPress(slide) : () => {}
            }
            onRetryUpload={onRetryUpload}
            onRemoveUploadingItem={onRemoveUploadingItem}
            padding={1}
            enableGallerySelection={enableGallerySelection}
            selectedItems={selectedItems}
          />
        )
      }}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item) => `sectionItem${item.slideId}`}
    />
  )
}

export default ClipSlideMediaSection
