export default {
  APP_VERSION: 'appVersion',
  BUSINESS_SEARCH_WITH_FACETS: 'businessSearchWithFacets',
  PASSWORD: 'password',
  SIGNIN: 'signin',
  USER_ORG_ROLES: 'userOrgRoles',
  MY_PROFILE: 'myProfile',
  USER: 'users',
  BUSINESS: 'business',
  BUSINESS_ACCOUNT_STORAGE: 'businessAccountStorage',
  BUSINESS_GENERATE_HANDLER: 'businessGenerateHandler',
  BUSINESS_ROLES: 'businessRoles',
  BUSINESS_REQUESTED_MEMBERS: 'businessRequestedMembers',
  BUSINESS_SEARCH_RESULTS: 'businessSearchResults',
  BUSINESS_PROFILE_COMPLETION: 'businessProfileCompletion',
  BUSINESS_TRADE_SERVICES: 'businessTradeServices',
  CHECK_UNCLAIMED_BUSINESS_EXIST: 'checkUmclaimedBusinessExist',
  SUPPLIERS_DETAILS: 'suppliersDetails',
  THREADS: 'threads',
  THREAD: 'thread',
  TRADES: 'trades',
  MESSAGES: 'messages',
  THREAD_MEDIA: 'threadMedia',
  THREADS_ALL: 'threadsAll',
  UNREAD_THREADS_COUNT: 'unreadThreadsCount',
  CLIP: 'clip',
  CLIP_HIGHLIGHTS: 'clipHighlights',
  YOUR_CLIPS: 'yourClips',
  TAGGED_CLIPS: 'taggedClips',
  BADGE_COUNTS: 'badgeCount',
  NOTIFICATION_LIST: 'notificationList',
  USER_ONLINE_STATUS: 'userOnlineStatus',
  TEAM_MEMBERS: 'teamMembers',
  SEARCH_DIRECTORY: 'searchDirectory',
  MAGIC_LINK: 'magicLink',
  DELIVERY_INFO: 'deliveryInfo',
  SEARCH_BUSINESSES: 'searchBusinesses',
  BUSINESS_LICENSES: 'businessLicenses',
  BUSINESS_SERVICE_AREAS: 'businessServiceAreas',
  ABN_LIST: 'abnList',
  THREAD_SEARCH: 'threadSearch',
  WEATHER: 'weather',
  WIDGETS: 'widgets',
  LOCATIONS: 'locations',
  PLACE_DETAILS: 'placeDetails',
  LICENCE_NUMBER: 'licenceNumber',
  LICENCE_SCANNED_ID: 'licenceScannedId',
  UNCLAIMED_BUSINESS: 'unclaimedBusiness',
  INVITATION: 'invitation',
  BUSINESS_LATEST_TODO_LISTS: 'businessTodoLists',
  USER_TASKS_STATUS_WISE: 'userTasksStatusWise',
  TASK_BY_ID: 'taskById',
  TODOLIST_BY_ID: 'todoListById',
  TODOLIST_TASKS_BY_TODO_ID: 'todoListTasksByTodoId',
  TODO_DETAILS_LOCATIONS: 'todoDetailsLocations',
  TODO_OUTSTANDING_TASKS: 'todoOutstandingTasks',
}
