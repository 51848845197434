import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import { StorageUtil } from '../common'

const analytics = Analytics({
  app: 'GoTradie',
  plugins: [
    segmentPlugin({
      writeKey: process.env.SEGMENT_WRITE_KEY,
    }),
  ],
})

type segmentUserParams = {
  user_id: string | undefined
  phone_number?: string | undefined
  first_name?: string | undefined
  last_name?: string | undefined
  email?: string | undefined
  profile_type?: string | undefined
  tradie_type?: string | undefined
  org_id?: string | undefined
}

export async function segmentIdentifyUser(
  userId?: string,
  userParams?: segmentUserParams
) {
  const activeRole = await StorageUtil.getActiveRole()
  let org_id = null
  if (activeRole?.['org-id']) {
    org_id = activeRole?.['org-id']
  }
  if (userId) {
    const userParamsFiltered = {
      phone_number: userParams?.phone_number,
      org_id: org_id,
      first_name: userParams?.first_name,
      last_name: userParams?.last_name,
      email: userParams?.email,
      profile_type: userParams?.profile_type,
    }
    await analytics.identify(userId, userParamsFiltered)
  }
}

export async function segmentLogEvent(eventName: string, eventParams?: object) {
  const activeRole = await StorageUtil.getActiveRole()
  const org_id = activeRole?.['org-id'] || null

  const defaultEventParameters = {
    user_source: 'web',
    org_id: org_id,
  }

  await analytics.track(eventName, {
    ...eventParams,
    ...defaultEventParameters,
  })
}
