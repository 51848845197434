import { ReactNode, useRef, useState } from 'react'
import { StyleProp, TouchableOpacity } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { AcceptedDocument, AcceptedMedia, GalleyType, ImageUploadFolderType, UploadItem } from '../types/Files'
import { MessageUtils } from '../common'

const { styles } = StyleSheet.create({
  inputStyle: {
    display: 'none',
  },
})

type FileChooserProps = {
  containerStyle: StyleProp<any>
  displayElement: ReactNode
  acceptTypes?: Array<GalleyType>
  isMultiple?: boolean
  isFileItem?: boolean
  onSelectFiles: (files: UploadItem[]) => void
  disabled?: boolean
  imageUploadFolder?: ImageUploadFolderType
  accept?: AcceptedMedia | AcceptedDocument
}

const types = [
  {
    description: 'Images',
    name: GalleyType.photo,
    accept: '.png,.jpg,.jpeg,.heif,.heic,.webp',
  },
  {
    description: 'Videos',
    name: GalleyType.video,
    accept: '.mp4',
  },
  {
    description: 'Audios',
    name: GalleyType.audio,
    accept: '.mp3',
  },
  {
    description: 'Files',
    name: GalleyType.docs,
    accept: '.*',
  },
]

const FileChooser = (props: FileChooserProps) => {
  const {
    containerStyle,
    displayElement,
    acceptTypes,
    isMultiple,
    isFileItem,
    onSelectFiles,
    disabled,
    imageUploadFolder,
    accept,
  } = props
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const [supportTypes, setSupportTypes] = useState<string | undefined>(
    accept ? Object.values(accept as {}).join(',') : undefined
  )

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (isFileItem) {
      const FileItems = await MessageUtils.formatUploadedItems(
        fileList || ({} as FileList),
        isFileItem,
        imageUploadFolder
      )
      if (!!FileItems) {
        onSelectFiles(FileItems)
      }
    } else {
      const items = await MessageUtils.formatUploadedItems(
        fileList || ({} as FileList),
        !isFileItem,
        imageUploadFolder
      )
      if (!!items) {
        onSelectFiles(items)
      }
    }
  }
  const handleClick = () => {
    hiddenFileInput.current?.click()
  }

  return (
    <>
      <TouchableOpacity
        onPress={handleClick}
        disabled={disabled}
        style={containerStyle}
      >
        {displayElement}
      </TouchableOpacity>
      <input
        type={'file'}
        ref={hiddenFileInput}
        onChange={(event) => {
          handleChange(event)
          event.currentTarget.value = ''
        }}
        style={styles.inputStyle}
        accept={supportTypes}
        multiple={isMultiple}
      />
    </>
  )
}

export default FileChooser
