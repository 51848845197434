import { AppColors, Modal } from '@gotradie/gt-components'

type WarningModalProps = {
  isVisible: boolean
  title: string
  subTitle: string
  acceptButtonProp: any
  onAccept: () => void
  onCancel: () => void
}

const WarningModal = ({
  isVisible,
  title,
  subTitle,
  acceptButtonProp,
  onAccept,
  onCancel,
}: WarningModalProps) => {
  return (
    <Modal
      isVisible={isVisible}
      opacity={0.5}
      title={title}
      message={subTitle}
      acceptButtonText="DELETE"
      cancelButtonText="CANCEL"
      width={400}
      height={250}
      onAccept={onAccept}
      acceptButtonProps={acceptButtonProp}
      onCancel={onCancel}
    />
  )
}

export default WarningModal
