import { useQuery } from 'react-query'
import { QueryKeys, getAxios, Constants, Util } from '../../common'
import { ServerError } from '../../types/Common'
import {
  Business,
  Metadata,
  PendingBusiness,
  Profile,
  Service,
  SignupStage,
  Trade,
  UserOrgRole,
} from '../../types/User'
import { AxiosResponse } from 'axios'
import { useActiveOrgId } from '../../context/UserContext'
import { MemberRole } from '../../types/Business'

const api = getAxios()

//-------------Fetch User Org Roles--------------

const fetchUserOrgRoles = async () => {
  return await api.get('/user-profile/roles')
}

export const useFetchUserOrgRoles = (
  enabled = true,
  onErrorCallback: (e: ServerError) => void
) => {
  return useQuery(QueryKeys.USER_ORG_ROLES, fetchUserOrgRoles, {
    enabled,
    placeholderData: {
      data: {
        placeholder: true,
        user: {
          roles: [
            { 'is-loading': true } as UserOrgRole,
            { 'is-loading': true } as UserOrgRole,
            { 'is-loading': true } as UserOrgRole,
            { 'is-loading': true } as UserOrgRole,
            { 'is-loading': true } as UserOrgRole,
            { 'is-loading': true } as UserOrgRole,
          ],
        },
      },
    } as AxiosResponse,
    select: (response) => {
      const roles = response?.data?.user?.roles || []
      const userOrgRoles = roles.map((item: UserOrgRole) => {
        if (!item['org-type']) {
          item['org-type'] = Constants.ORGANIZATION_TYPES.TRADE_ORG
        }
        return item
      })
      return [...userOrgRoles, { type: 'new' } as UserOrgRole]
    },
    onError: (error) => {
      onErrorCallback(error as ServerError)
    },
    cacheTime: Constants.DURATION.DAY,
    staleTime: Constants.DURATION.DAY,
  })
}

//------------------Fetch User--------------
const fetchUser = async (userId = '') => {
  return await api.get(`/user-profile/${userId}`)
}

const fetchUserOnlineStatus = async (userId = '') => {
  return await api.get(`/reporting/check-online-status?user-filter=${userId}`)
}

const useFetchUser = (
  queryKey: string | string[],
  userId: string,
  enabled = true,
  staleTime: number,
  cacheTime = Constants.DURATION.DAY
) => {
  return useQuery(queryKey, () => fetchUser(userId), {
    enabled,
    select: (response) => {
      const responseUser = response?.data?.user
      return dtoToProfile(responseUser)
    },
    cacheTime,
    staleTime,
  })
}

export const useFetchMyProfile = (enabled = true) => {
  return useFetchUser(
    [QueryKeys.MY_PROFILE, useActiveOrgId()],
    '',
    enabled,
    Constants.DURATION.WEEK,
    Constants.DURATION.WEEK
  )
}

export const useFetchUserProfile = (userId: string, enabled: boolean) => {
  return useFetchUser(
    [QueryKeys.USER, userId],
    userId,
    enabled,
    Constants.DURATION.DAY,
    Constants.DURATION.DAY
  )
}

export function dtoToProfile(profileResponse: any) {
  const roleType =
    profileResponse['role-type'] || profileResponse['active-role-type']
  const profile: Profile = {} as Profile
  profile.firstName = profileResponse['first-name']
  profile.phoneNumber = profileResponse['phone-number']
  profile.lastName = profileResponse['last-name']
  profile.email = profileResponse['email']
  profile.name = profileResponse['name']
  profile.emailVerificationToken = profileResponse['email-verification-token']
  profile.emailAddress = profileResponse['email-address']
  profile.userId = profileResponse['user-id']
  profile.profileType = profileResponse['profile-type']
  profile.profilePic = profileResponse['profile-pic']
  profile.invitationId = profileResponse['invitation-id']
  profile.emailVerified = profileResponse['email-verified']
  profile.profilePicUrl = profileResponse['profile-pic-url']
  profile.roleStatus = profileResponse['role-status']
  profile.roleTemplateDisplayName =
    profileResponse?.['role-template-display-name'] || ''
  profile.roleType = roleType
  profile.chatFilters = profileResponse['chat-filters']
  profile.tradieType = Util.getTradieTypeFromRole(roleType)

  profile.signupStages = {} as SignupStage
  if (profileResponse['signup-stages']) {
    const value = profileResponse['signup-stages']
    const item: SignupStage = {} as SignupStage
    item.assignedBusiness = value['assigned-business']
    item.confirmedAccountCreation = value['confirmed-account-creation']
    item.createdBusiness = value['created-business']
    item.enteredEmail = value['entered-email']
    item.enteredName = value['entered-name']
    item.rejectedFromBusiness = value['rejected-from-business']
    item.requestedBusiness = value['requested-business']
    item.selectedProfileType = value['selected-profile-type']
    profile.signupStages = item
  }

  profile.metadata = {} as Metadata
  if (profileResponse['metadata']) {
    const value = profileResponse['metadata']
    const item: Metadata = {} as Metadata
    item.startedTime = value['started-time']
    item.endTime = value['end-time']
    profile.metadata = item
  }

  const services: Service[] = []
  if (profileResponse['services']) {
    for (const key of Object.keys(profileResponse['services'])) {
      const value = profileResponse['services'][key]
      const item: Service = {} as Service
      item.serviceId = value['service-id']
      item.serviceHashtag = value['service-hashtag']
      item.name = value['name']
      services.push(item)
    }
  }
  profile.services = services

  const trades: string[] = []
  const businessTrades: Trade[] = []
  if (profileResponse['business-trades']) {
    for (const key of Object.keys(profileResponse['business-trades'])) {
      const value = profileResponse['business-trades'][key]
      const item: Trade = {} as Trade
      item.tradeHash = value['trade-hash']
      item.tradeName = value['trade-name']
      businessTrades.push(item)
      trades.push(value['trade-name'])
    }
  }
  profile.businessTrades = businessTrades
  profile.trades = trades

  const pendingBusinesses: PendingBusiness[] = []
  if (profileResponse['pending-business']) {
    for (const key of Object.keys(profileResponse['pending-business'])) {
      const value = profileResponse['pending-business'][key]
      const item: PendingBusiness = {} as PendingBusiness
      item.businessId = key
      item.status = value['status'] || ''
      pendingBusinesses.push(item)
    }
  }
  profile.pendingBusiness = pendingBusinesses

  const businesses: Business[] = []
  if (profileResponse['business']) {
    for (const key of Object.keys(profileResponse['business'])) {
      const value = profileResponse['business'][key]
      const item: Business = {} as Business
      item.businessId = key
      item.businessName = value['business-name'] || ''
      item.effectiveUserId = value['effective-user-id'] || ''
      item.roleType = value['role-type'] || ''
      item.handle = value['handle']
      businesses.push(item)
    }
  }
  profile.business = businesses

  return profile
}

export const useFetchUserOnlineStatus = (userId: string, enabled = true) => {
  return useQuery(
    [QueryKeys.USER_ONLINE_STATUS, userId],
    () => fetchUserOnlineStatus(userId),
    {
      enabled,
      initialData: undefined,
      select: (data) => {
        const response = data?.data
        if (response) {
          return response
        } else {
          return undefined
        }
      },
    }
  )
}

export function dtoToUserRole(response: any): MemberRole {
  const userRole: MemberRole = {
    permissionTemplate: response['permissions-template'] || {},
    roleTemplateDisplayName: response['role-template-display-name'],
    roleTemplateId: response['role-template-id'],
    roleType: response['role-type'],
  }

  return userRole
}
