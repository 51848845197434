import {
  DocumentData,
  DocumentReference,
  GeoPoint,
  Timestamp,
} from 'firebase/firestore'
import { Coordinate } from './Business'
import { TaskBuilder, TodoListBuilder } from '../prototypes/TodoList'

export enum FirebaseCollections {
  ToDoListCollection = 'todoLists',
  TaskCollection = 'tasks',
  DeletedTaskCollection = 'deletedTasks',
  TaskStatusChangeHistory = 'taskStatusChangeHistory',
  DeletedToDoListCollection = 'deletedToDoLists',
  DeletedTodoListTasksCollection = 'deletedTodoListsTasks', // These tasks removed because of todo list deletion
}

export interface User {
  userId: string
  firstName: string
  lastName: string
  profilePic: string
  roleTemplateDisplayName: string
  businessName: string
  businessId: string
  effectiveUserId: string
  phoneNumber: string
  email: string
}

export enum PublishStatus {
  draft = 'draft',
  publish = 'publish',
}
export enum TodoStatus {
  open = 'OPEN',
  close = 'CLOSE',
}

export enum TaskStatus {
  outStanding = 'OUTSTANDING',
  completed = 'COMPLETED',
}

export type TaskStatusObject = {
  key: TaskStatus
  name: string
  des: string
}

export type TaskStatusKeyValPair = {
  [key: string]: TaskStatusObject
}

export type Location = {
  suburb?: string
  coordinates?: Coordinate
  name?: string
  state?: string
}

export type FirebaseLocation = {
  suburb?: string
  coordinates?: GeoPoint
  name?: string
  state?: string
}

export interface TaggedUsers {
  [effectiveUserId: string]: User
}

export type Period = { allDay: boolean; startDate: Date; endDate: Date }
export type FirebasePeriod = {
  allDay: boolean
  startDate: Timestamp
  endDate: Timestamp
}

export type TodoListScreenProps = {
  todoList?: TodoListBuilder
  task?: TaskBuilder
}
export type TodoListScreenParaProps = {
  isNew: boolean
  todoListId?: string
  taskId?: string
}

export type TodoListRightPanelScreensType = {
  screenKey: TodoListRightPanelScreenKeys
  todoList: TodoListBuilder
  todoListTask?: TaskBuilder
}

export enum TodoListRightPanelScreenKeys {
  TO_DO_LIST = 'TO_DO_LIST', //only apply for chat screen
  TO_DO_LIST_TASK_DETAILS = 'TO_DO_LIST_TASK_DETAILS',
  TO_DO_LIST_DETAILS = 'TO_DO_LIST_DETAILS',
}

export enum TodoListTaskRightPanelScreenKeys {
  TO_DO_LIST_TASK_DETAIL = 'TO_DO_LIST_TASK_DETAIL',
  TO_DO_LIST_TASK_ASSIGNEE = 'TO_DO_LIST_TASK_ASSIGNEE',
}

export type NavigationTodoListDetailsProps = {
  todoList: TodoListBuilder
}
export type NavigationTodoListTaskDetailsProps = {
  todoList: TodoListBuilder
  task: TaskBuilder
}

export type TodoListTaskRef = {
  [key: string]: { reference: DocumentReference<DocumentData> }
}

export type TodoTaskCreateResponse = {
  message: string
  todoListBuild: TodoListBuilder
  taskBuild?: TaskBuilder
  isNewTask?: boolean
  isNewTodoList?: boolean
  forceBack?: boolean
}

export type TodoTaskDeleteResponse = {
  taskBuild?: TaskBuilder
}
